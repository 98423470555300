import { action, decorate, observable } from 'mobx'

import { arrayOf } from 'utils/arrays'

import User from 'models/User'
import Advisor from 'models/Advisor'
import CourseStatus from 'pages/courses/data/CourseStatus'

export default class Student extends User {
  constructor({
    advisorId,
    advisor,
    courseStatuses,
    staticAdvisor,
    ...args
  } = {}) {
    super(args)

    this.advisorId = advisorId
    this.staticAdvisor = staticAdvisor
    this.courseStatuses = arrayOf({
      model: CourseStatus,
      withItems: courseStatuses,
    })
    this.setAdvisor(advisor)
  }

  // Observables

  advisorId
  courseStatuses = []
  staticAdvisor = false
  username

  advisor

  // Actions

  setAdvisor = data => {
    this.advisor = data && new Advisor(data)
  }

  setStaticAdvisor = value => (this.staticAdvisor = value)

  update = ({ advisorId, username, ...args } = {}) => {
    super.update(args)

    this.advisorId = advisorId
    this.username = username
  }
}

decorate(Student, {
  advisorId: observable,
  courseStatuses: observable,
  advisor: observable,
  staticAdvisor: observable,
  username: observable,

  setAdvisor: action,
  setStaticAdvisor: action,
  update: action,
})
