import { action, decorate, observable } from 'mobx'

export default class Slide {
  constructor(args) {
    Object.assign(this, args)
  }

  // Observables

  id = null
  isEnabled = true
  isDeleted = false
  createdAt = new Date()
  updatedAt = new Date()

  content = []
  position = 0
  title = ''
  type = ''

  status = {}

  // Actions

  setStatus = status => {
    this.status = { ...this.status, ...status }
  }

  update = ({ content, status, ...rest }) => {
    Object.assign(this, rest)
    this.content = content
    this.setStatus(status)
  }
}

decorate(Slide, {
  id: observable,
  isEnabled: observable,
  isDeleted: observable,
  createdAt: observable,
  updatedAt: observable,

  content: observable,
  position: observable,
  title: observable,
  type: observable,

  status: observable,

  update: action,
})
