import React from 'react'

import { observer } from 'mobx-react'
import { Route } from 'react-router-dom'

import {
  useRedirectUnlessAdvisor,
  useRedirectUnlessStudent,
  useRedirectUnlessUser,
  useRedirectWhenUser,
} from 'stores/AuthContext'

export const NoUserRoute = observer(({ children, redirectTo, ...props }) => {
  const user = useRedirectWhenUser(redirectTo)
  if (user) return null

  return <Route {...props}>{children}</Route>
})

export const UserRoute = observer(({ children, redirectTo, ...props }) => {
  const isUser = useRedirectUnlessUser(redirectTo)
  if (!isUser) return null

  return <Route {...props}>{children}</Route>
})

export const StudentRoute = observer(({ children, redirectTo, ...props }) => {
  const isStudent = useRedirectUnlessStudent(redirectTo)
  if (!isStudent) return null

  return <Route {...props}>{children}</Route>
})

export const AdvisorRoute = observer(({ children, redirectTo, ...props }) => {
  const isAdvisor = useRedirectUnlessAdvisor(redirectTo)
  if (!isAdvisor) return null

  return <Route {...props}>{children}</Route>
})
