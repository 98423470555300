import React from 'react'

import PropTypes from 'prop-types'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (error.name && error.name === 'ChunkLoadError') {
      window.location.reload()
    } else if (typeof this.props.reportCallback === 'function') {
      console.error(error, errorInfo) // eslint-disable-line no-console
      this.props.reportCallback(error, errorInfo)
    } else {
      console.error(error, errorInfo) // eslint-disable-line no-console
    }
  }

  render() {
    if (this.state.hasError) {
      // Render custom fallback UI
      return this.props.fallback
    }

    return this.props.children
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.node,
  reportCallback: PropTypes.func,
}
ErrorBoundary.defaultProps = {}
