/**
 * Checks if a value is set (not `null` nor `undefined`)
 * @param {any} value the value to Check
 * @return `Boolean`
 */
export function isSet(value) {
  return value !== undefined && value !== null;
}

/**
 * Checks if provided `value` is of given `type`
 *
 * @param {any} value the value to check
 * @param {string} type Supported types: `array`, `boolean`, `function`, `object`, `string`, and `undefined`
 * @returns boolean
 */
export function isOfType(value, type) {
  return type === 'array'
    ? Array.isArray(value)
    : isSet(value) && typeof value === type;
}

/**
 * Check if value is an object
 * @param {any} value
 */
export function isObject(value) {
  return isOfType(value, 'object');
}

/**
 * Check if value is a function
 * @param {any} value
 */
export function isFunction(value) {
  return isOfType(value, 'function');
}

/**
 * Check if value is a string
 * @param {any} value
 */
export function isString(value) {
  return isOfType(value, 'string');
}

/**
 * Check if value is a number
 * @param {any} value
 */
export function isNumber(value) {
  return isOfType(value, 'number');
}

/**
 * Check if value is an array
 * @param {any} value
 */
export function isArray(value) {
  return isOfType(value, 'array');
}
