import React, { lazy } from 'react'

const Button = lazy(() => import('ui/Button'))

export default function UpdateApp() {
  const isAndroid = navigator.userAgent.toLowerCase().includes('android')
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  const updateLink = isIOS
    ? 'itms-apps://apps.apple.com/de/app/hope-kurse/id1567051168' // https://apps.apple.com/de/app/hope-kurse/id1567051168
    : isAndroid
    ? 'market://details?id=de.hopekurse.app' // https://play.google.com/store/apps/details?id=de.hopekurse.app
    : null

  return (
    <div className="w-full h-screen fixed top-0 left-0 z-max bg-white">
      <div className="flex flex-col justify-between h-full">
        <div className="w-full bg-primary-500 text-white flex-1 p-10 flex items-center">
          <div>
            <div className="w-24 h-24 bg-white rounded-lg">
              <img
                src="/images/meine-hopekurse-de/newAppLogo.png"
                alt="Hope Kurse"
              />
            </div>
            <div className="text-2xl font-bold mt-8">
              Ein neues Update ist verfügbar
            </div>
            <div className="mt-4">
              Die aktuelle Version dieser Anwendung wird nicht mehr unterstützt.
              Wir entschuldigen uns für etwaige Unannehmlichkeiten. Bitte
              aktualisieren Sie die App auf die neueste Version.
            </div>
          </div>
        </div>
        {updateLink && (
          <div className="w-full bg-white p-10">
            <Button
              label="Jetzt aktualisieren"
              className="w-full"
              external
              to={updateLink}
            />
          </div>
        )}
      </div>
    </div>
  )
}
