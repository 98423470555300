import { action, computed, decorate, observable } from 'mobx'

import { isArray } from 'utils/arrays'

import QuestionnaireStatus from './QuestionnaireStatus'
import Course from './Course'
import Lesson from './Lesson'
import Slide from './Slide'

import Student from 'models/Student'

export default class CourseStatus {
  constructor({
    activeLesson,
    activeSlide,
    course,
    questionnaireStatuses = [],
    reachedLesson,
    student,
    ...args
  }) {
    Object.assign(this, args)

    this.activeLesson = activeLesson && new Lesson(activeLesson)
    this.activeSlide = activeSlide && new Slide(activeSlide)
    this.course = course && new Course(course)
    this.reachedLesson = reachedLesson && new Lesson(reachedLesson)
    this.student = student && new Student(student)

    if (isArray(questionnaireStatuses)) {
      for (const questionnaireStatus of questionnaireStatuses) {
        const { questionnaireId } = questionnaireStatus
        this.questionnaireStatuses[questionnaireId] = new QuestionnaireStatus(
          questionnaireStatus
        )
      }
    }
  }

  // Observables

  id = null
  isEnabled = true
  isDeleted = false
  createdAt = false
  updatedAt = false

  courseId = null
  studentId = null
  finishedAt = null
  reachedLessonId = null
  activeLessonId = null
  activeSlideId = null
  slidesRead = []

  // course = null
  reachedLesson = null
  activeLesson = null
  activeSlide = null
  student = null
  questionnaireStatuses = {}

  // computed

  get isStarted() {
    return !!(this.createdAt && this.studentId)
  }

  // Actions

  setActiveSlide = value => (this.activeSlide = value)

  updateQuestionnaireStatus = questionnaireStatus => {
    const { questionnaireId } = questionnaireStatus
    this.questionnaireStatuses[questionnaireId] = questionnaireStatus
  }

  setSlideRead = slideId =>
    !this.slidesRead.includes(slideId) && this.slidesRead.push(slideId)
}

decorate(CourseStatus, {
  id: observable,
  isEnabled: observable,
  isDeleted: observable,
  createdAt: observable,
  updatedAt: observable,

  courseId: observable,
  studentId: observable,
  finishedAt: observable,
  reachedLessonId: observable,
  activeLessonId: observable,
  activeSlideId: observable,
  slidesRead: observable,
  questionnaireStatuses: observable,

  course: observable,
  reachedLesson: observable,
  activeLesson: observable,
  activeSlide: observable,
  student: observable,

  isStarted: computed,

  updateQuestionnaireStatus: action,
  setActiveSlide: action,
  setSlideRead: action,
})
