import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { observer, useLocalStore } from 'mobx-react'

import { useAuthContext } from 'stores/AuthContext'
import MessagesStore from './MessagesStore'

const MessagesContext = React.createContext()

const noStore = {}

export const MessagesProvider = observer(({ children }) => {
  const { user } = useAuthContext()

  // Init store
  const store = useLocalStore(() => new MessagesStore())

  useEffect(() => {
    if (user) {
      store.sseSubscribe(user)
    } else {
      store.sseUnsubscribe()
    }
  }, [store, user])

  return (
    <MessagesContext.Provider value={user ? store : noStore}>
      <MessagesToReadLoader>{children}</MessagesToReadLoader>
    </MessagesContext.Provider>
  )
})

MessagesProvider.propTypes = {
  children: PropTypes.node,
}

function MessagesToReadLoader({ children }) {
  useMessagesToRead()
  return children
}

export function useMessagesContext() {
  return useContext(MessagesContext)
}

export function useMessagesToRead() {
  const { user } = useAuthContext()
  const messagesStore = useMessagesContext()
  const { messagesToRead, loading } = messagesStore

  useEffect(() => {
    if (!user) return
    messagesStore.countMessagesToRead(user)
  }, [messagesStore, user])

  return { messagesToRead, loading }
}
