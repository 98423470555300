import { action, computed, decorate, observable } from 'mobx'

import Slide from './Slide'

export default class Lesson {
  constructor({ slides, ...args }) {
    Object.assign(this, args)

    this.setSlides(slides)
  }

  // Observables

  id = null
  isEnabled = true
  isDeleted = false
  createdAt = null
  updatedAt = null

  content = []
  position = 0
  slidesMap = {}
  title = ''

  status = {}

  // Computed

  get slides() {
    return Object.keys(this.slidesMap).map(key => this.slidesMap[key])
  }

  // Actions

  setSlides = slides => {
    this.slidesMap = slides
      ?.sort((a, b) =>
        a.position < b.position ? -1 : a.position > b.position ? 1 : 0
      )
      ?.reduce((acc, slide, index) => {
        if (slide?.slug) {
          acc[slide.slug] = new Slide({
            ...slide,
            position: index + 1,
          })
        }
        return acc
      }, {})
  }

  setStatus = status => {
    this.status = { ...this.status, ...status }
  }

  update = ({ content, status, ...rest }) => {
    Object.assign(this, rest)
    this.content = content
    this.setStatus(status)
  }
}

decorate(Lesson, {
  id: observable,
  isEnabled: observable,
  isDeleted: observable,
  createdAt: observable,
  updatedAt: observable,

  content: observable,
  slidesMap: observable,
  status: observable,
  position: observable,
  title: observable,

  slides: computed,

  setSlides: action,
  setStatus: action,
  update: action,
})
