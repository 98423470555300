import React, { lazy } from 'react'

import { observer } from 'mobx-react'
import { Redirect, Route, Switch } from 'react-router-dom'

import {
  AdvisorRoute,
  NoUserRoute,
  StudentRoute,
  UserRoute,
} from 'utils/routes'

// const DesignRouter = lazy(() => import('pages/design/Router'))
const AdvisorsRouter = lazy(() => import('pages/advisors/Router'))
const CoursesRouter = lazy(() => import('pages/courses/Router'))
const StudentsRouter = lazy(() => import('pages/students/Router'))
const MessagesRouter = lazy(() => import('pages/messages/Router'))
const QuestionnairesRouter = lazy(() => import('pages/questionnaires/Router'))
const UserRouter = lazy(() => import('pages/user/Router'))
const NotFound = lazy(() => import('pages/NotFound'))
const Imprint = lazy(() => import('pages/Imprint'))
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'))
const TermsOfUse = lazy(() => import('pages/TermsOfUse'))

const UpdateApp = lazy(() => import('pages/UpdateApp'))
const Login = lazy(() => import('pages/session/Login'))
const Register = lazy(() => import('pages/session/Register'))
const ResetPassword = lazy(() => import('pages/session/ResetPassword'))
const VerifyAccount = lazy(() => import('pages/session/VerifyAccount'))
const ForgotPassword = lazy(() => import('pages/session/ForgotPassword'))
const ResendVerificationEmail = lazy(() =>
  import('pages/session/ResendVerificationEmail')
)

function Router() {
  return (
    <Switch>
      {/* <Route path="/design">
        <DesignRouter />
      </Route> */}
      <Route path="/courses">
        <CoursesRouter />
      </Route>
      <Route path="/" exact>
        <Redirect to="/courses" />
      </Route>
      
      <NoUserRoute path="/login" exact>
        <Login type="student" />
      </NoUserRoute>
      <NoUserRoute path="/login/advisors" exact>
        <Login type="account" />
      </NoUserRoute>
      <NoUserRoute path="/reset-password" exact>
        <ResetPassword type="student" />
      </NoUserRoute>
      <NoUserRoute path="/reset-password/advisors" exact>
        <ResetPassword type="account" />
      </NoUserRoute>
      <NoUserRoute path="/request-password-reset" exact>
        <ForgotPassword type="student" />
      </NoUserRoute>
      <NoUserRoute path="/request-password-reset/advisors" exact>
        <ForgotPassword type="account" />
      </NoUserRoute>
      <NoUserRoute path="/verify-account">
        <VerifyAccount />
      </NoUserRoute>
      <NoUserRoute path="/resend-email">
        <ResendVerificationEmail />
      </NoUserRoute>
      <NoUserRoute path="/register">
        <Register />
      </NoUserRoute>

      <UserRoute path="/messages">
        <MessagesRouter />
      </UserRoute>
      <UserRoute path="/user-settings">
        <UserRouter />
      </UserRoute>

      <StudentRoute path="/advisors">
        <AdvisorsRouter />
      </StudentRoute>

      <AdvisorRoute path="/students">
        <StudentsRouter />
      </AdvisorRoute>
      <AdvisorRoute path="/questionnaires">
        <QuestionnairesRouter />
      </AdvisorRoute>

      <Route path="/update-app">
        <UpdateApp />
      </Route>
      <Route path="/imprint">
        <Imprint />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/terms-of-use">
        <TermsOfUse />
      </Route>

      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default observer(Router)
