import React from 'react'
import PropTypes from 'prop-types'

export default function ErrorScreen({ t }) {
  return (
    <div className="flex flex-col items-center justify-center bg-white">
      <div className="flex flex-col items-center justify-between h-screen p-8 md:max-w-4xl lg:max-w-6xl md:flex-row md:space-x-4">
        <div className="flex items-center justify-center max-w-sm p-4 lg:px-8 md:max-w-lg">
          <img src="/images/error.svg" alt="Error lady and bug" />
        </div>
        <div className="flex flex-col flex-grow p-8 space-y-6 md:justify-center">
          <div className="space-y-2">
            <h3 className="text-xl font-semibold text-danger-600">
              {t('error')}
            </h3>
            <h1 className="text-4xl font-semibold text-primary-700">
              {t('errorTitle')}
            </h1>
          </div>
          <p className="text-lg text-gray-600">{t('errorMessage')}</p>
          <p>
            <a
              href="/"
              className="text-primary-600 hover:underline hover:text-primary-400"
            >
              {t('errorGoHome')}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

ErrorScreen.propTypes = {
  t: PropTypes.func,
}
ErrorScreen.defaultProps = {
  t: x => x,
}
