import { action, computed, decorate, observable } from 'mobx'

import Lesson from './Lesson'
import CourseStatus from './CourseStatus'

import Advisor from 'models/Advisor'
import ImageFile from 'models/ImageFile'

export default class Course {
  constructor({
    advisor,
    totalLessons, // eslint-disable-line no-unused-vars
    image = {},
    lessons = [],
    status,
    ...args
  }) {
    Object.assign(this, args)

    this.setLessons(lessons)
    this.setStatus(status)

    this.advisor = advisor && new Advisor(advisor)
    this.image = image && new ImageFile(image)
  }

  // Observables

  id = null
  isEnabled = true
  isDeleted = false

  name = ''
  slug = ''
  abstract = ''
  description = ''
  trailer = null
  image = null
  onlineCourse = true
  printCourse = false
  requiresRevision = true
  analyticsCampaignId = ''
  lessonsMap = {}
  lessonSlug = null
  slideSlug = null

  status = null

  entityId = null
  languageId = null
  advisorId = null

  advisor = null

  // Computed

  get lessons() {
    return Object.keys(this.lessonsMap).map(key => this.lessonsMap[key])
  }

  get totalLessons() {
    return this.lessons.length
  }

  get firstLesson() {
    return this.lessons[0]
  }

  get reachedLesson() {
    return this.getLesson(this.status?.reachedLessonId)
  }

  get lesson() {
    return this.lessonsMap?.[this.lessonSlug]
  }

  get prevLesson() {
    if (!this.lesson) return null
    const { position } = this.lesson
    const prevIndex = position === 1 ? null : position - 2
    return this.lessons?.[prevIndex]
  }

  get nextLesson() {
    if (!this.lesson) return null
    const { position } = this.lesson
    const nextIndex = position < this.lessons?.length ? position : null
    return nextIndex ? this.lessons?.[nextIndex] : null
  }

  get slide() {
    return this.lesson?.slidesMap[this.slideSlug]
  }

  get prevSlide() {
    if (!this.lesson || !this.slide) return null
    const { slides } = this.lesson
    const { position } = this.slide
    const prevIndex = position === 1 ? null : position - 2
    return slides?.[prevIndex]
  }

  get nextSlide() {
    if (!this.lesson) return null
    const { slides } = this.lesson
    const nextIndex = this.slide
      ? this.slide.position <= slides.length
        ? this.slide.position
        : null
      : 0

    return nextIndex >= 0 ? slides?.[nextIndex] : null
  }

  // Actions

  setStatus = value => {
    this.status = value ? new CourseStatus(value) : null
  }

  setLessons = lessons => {
    this.lessonsMap = lessons.reduce((acc, lesson, index) => {
      if (lesson?.slug) {
        acc[lesson.slug] = new Lesson({
          ...lesson,
          position: index + 1,
        })
      }
      return acc
    }, {})
  }

  setLesson = (slug, data) => {
    this.lessonSlug = slug
    if (slug && data) {
      this.lessonsMap[slug].update(data)
    }
  }

  setLessonSlug = slug => {
    this.lessonSlug = slug
  }

  setSlide = (slug, data) => {
    this.slideSlug = slug
    if (this.lesson && slug && data) {
      this.lesson?.slidesMap[slug].update(data)
    }
  }

  setSlideSlug = slug => {
    this.slideSlug = slug
  }

  // Helpers

  getLesson = id => {
    return this.lessons.find(l => l.id === id)
  }
}

decorate(Course, {
  id: observable,
  isEnabled: observable,
  isDeleted: observable,

  name: observable,
  slug: observable,
  abstract: observable,
  description: observable,
  languageId: observable,
  trailer: observable,
  image: observable,
  onlineCourse: observable,
  printCourse: observable,
  requiresRevision: observable,
  analyticsCampaignId: observable,
  lessonsMap: observable,
  lessonSlug: observable,
  slideSlug: observable,
  status: observable,

  lessons: computed,
  lesson: computed,
  slide: computed,
  firstLesson: computed,
  reachedLesson: computed,
  totalLessons: computed,

  setLessons: action,
  setLesson: action,
  setLessonSlug: action,
  setSlide: action,
  setSlideSlug: action,
  setStatus: action,
})
