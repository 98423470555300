import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import Icon from 'ui/Icon'

export default function Loading({ delay }) {
  const { t } = useTranslation()
  const [loadingWait, setLoadingWait] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setLoadingWait(false), delay)
    return () => clearTimeout(timer)
  }, [delay])

  return (
    <div
      className={`flex items-center justify-center w-full overflow-hidden py-8 space-x-2 ${
        loadingWait ? 'h-0' : 'h-6'
      }`}
    >
      <Icon className="text-2xl animate-spin" name="loader" />
      <span>{t('loading')}</span>
    </div>
  )
}

Loading.propTypes = {
  delay: PropTypes.number,
}
Loading.defaultProps = {
  delay: 500,
}
