import React from 'react'
import PropTypes from 'prop-types'

import { I18nextProvider } from 'react-i18next'

import i18n from 'config/i18n'

export default function I18nProvider({ children }) {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

I18nProvider.propTypes = {
  children: PropTypes.node,
}
