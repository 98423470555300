import React, { lazy, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useEntity } from 'stores/EntityContext'
import { useCookiesConsentContext } from 'stores/CookiesConsentContext'

const Button = lazy(() => import('ui/Button'))
const ButtonToggle = lazy(() => import('ui/ButtonToggle'))
const Accordion = lazy(() => import('ui/Accordion'))
const AccordionItem = lazy(() =>
  import('ui/Accordion').then(m => ({ default: m.AccordionItem }))
)

export function useScripts(scripts, enabled) {
  useEffect(() => {
    if (!scripts) return () => null

    for (const { id, code, consentLevel, head = false } of scripts) {
      const scriptId = `script-${consentLevel}-${id}`
      // Check if script already exists in the document
      const prevScript = document.getElementById(scriptId)

      const target = head ? document.head : document.body

      if (prevScript && !enabled) {
        // Remove already present script if is not longer enabled
        target.removeChild(prevScript)
      } else if (!prevScript && enabled) {
        // Create and insert a new script tag
        const newScript = document.createElement('script')
        newScript.type = 'text/javascript'
        newScript.id = scriptId
        newScript.innerHTML = code
        newScript.async = true
        target.appendChild(newScript)
      }
    }
  }, [scripts, enabled])
}

function CookieConsent() {
  const { t } = useTranslation('cookies')
  const entity = useEntity()
  const cookiesStore = useCookiesConsentContext()
  const { showSettings, functional, tracking, targeting } = cookiesStore

  // Sets temporary/ephimeral state for the toggle buttons
  // (so scripts are no added to the page until the user hits one of the "accept" buttons)
  const [functionalTemp, setFunctionalTemp] = useState()
  const [trackingTemp, setTrackingTemp] = useState()
  const [targetingTemp, setTargetingTemp] = useState()

  // Keeps temporary states updated with store values
  useEffect(() => {
    setFunctionalTemp(functional)
  }, [functional])

  useEffect(() => {
    setTrackingTemp(tracking)
  }, [tracking])

  useEffect(() => {
    setTargetingTemp(targeting)
  }, [targeting])

  // Insert/remove cookies scripts
  useScripts(entity?.functionalScripts, functional)
  useScripts(entity?.trackingScripts, tracking)
  useScripts(entity?.targetingScripts, targeting)

  const onAllowAll = useCallback(() => {
    cookiesStore.setCookiesConsent({
      necessary: true,
      functional: true,
      tracking: true,
      targeting: true,
    })
  }, [cookiesStore])

  const onAllowSelected = useCallback(() => {
    cookiesStore.setCookiesConsent({
      necessary: true,
      functional: functionalTemp,
      tracking: trackingTemp,
      targeting: targetingTemp,
    })
  }, [cookiesStore, functionalTemp, trackingTemp, targetingTemp])

  if (!entity) return null

  return (
    <div
      className={`transition-colors duration-300 ease-in-out ${
        showSettings
          ? 'fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center p-4 sm:p-6 md:p-8 bg-black bg-opacity-25 z-max'
          : 'hidden'
      }`}
    >
      <div className="flex flex-col max-w-2xl max-h-screen p-8 space-y-8 overflow-hidden overflow-y-auto bg-white rounded-lg shadow-2xl">
        <div className="space-y-4">
          <h2 className="text-2xl font-bold">{t('popupTitle')}</h2>
          <p>{t('popupDescription')} </p>

          <Accordion>
            <AccordionItem
              title={t('necessary')}
              description={t('necessaryHelp')}
              extra={<ButtonToggle active disabled />}
            />
            {entity.hasFunctionalityCookies && (
              <AccordionItem
                title={t('functionality')}
                description={t('functionalityHelp')}
                extra={
                  <ButtonToggle
                    active={functionalTemp}
                    onChange={() => setFunctionalTemp(!functionalTemp)}
                  />
                }
              />
            )}
            {entity.hasTrackingCookies && (
              <AccordionItem
                title={t('tracking')}
                description={t('trackingHelp')}
                extra={
                  <ButtonToggle
                    active={trackingTemp}
                    onChange={() => setTrackingTemp(!trackingTemp)}
                  />
                }
              />
            )}
            {entity.hasTargetingCookies && (
              <AccordionItem
                title={t('targeting')}
                description={t('targetingHelp')}
                extra={
                  <ButtonToggle
                    active={targetingTemp}
                    onChange={() => setTargetingTemp(!targetingTemp)}
                  />
                }
              />
            )}
          </Accordion>
        </div>
        <div className="flex flex-col items-stretch justify-between space-y-4 tracking-wide md:space-y-0 md:space-x-4 md:flex-row">
          <Button
            label={t('allowAll')}
            variant="primary"
            onClick={onAllowAll}
            t={t}
          />
          <Button
            label={t('allowSelected')}
            variant="secondary"
            onClick={onAllowSelected}
            t={t}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(CookieConsent)
