import React from 'react'
import PropTypes from 'prop-types'

import icons from 'config/icons'

const defaultIcon = 'alert-circle'

export default function Icon({ className, name, onClick, size }) {
  const ReactIcon = icons[name] ? icons[name] : icons[defaultIcon]
  return (
    <span
      className={`flex justify-center item-center ${
        onClick ? 'cursor-pointer' : ''
      } ${className}`}
      onClick={onClick}
    >
      <ReactIcon size={size} />
    </span>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.number,
}
Icon.defaultProps = {
  className: '',
}
