import React, { lazy, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { observer, useLocalStore } from 'mobx-react'

const HtmlHeader = lazy(() => import('utils/HtmlHeader'))

const Box = lazy(() => import('ui/Box'))
const Breadcrumbs = lazy(() => import('ui/Breadcrumbs'))
const Heading = lazy(() => import('ui/Heading'))

const PageContext = React.createContext({})

export const PageProvider = observer(({ children }) => {
  const store = useLocalStore(() => ({
    breadcrumbs: [],
    title: '',

    setBreadcrumbs: value => (store.breadcrumbs = value),
    setTitle: value => (store.title = value),
  }))

  return <PageContext.Provider value={store}>{children}</PageContext.Provider>
})

PageProvider.propTypes = {
  children: PropTypes.node,
}

export function usePageContext() {
  return useContext(PageContext)
}

export function usePageTitle(title) {
  const { setTitle } = usePageContext()

  useEffect(() => {
    if (title) {
      setTitle(title)
    }
  }, [title, setTitle])
}

export function usePageBreadcrumbs(breadcrumbs) {
  const { setBreadcrumbs } = usePageContext()

  useEffect(() => {
    if (breadcrumbs) {
      setBreadcrumbs(breadcrumbs)
    }
  }, [breadcrumbs, setBreadcrumbs])
}

function Page({
  breadcrumbs,
  children,
  className,
  contentClass,
  noBox,
  showHeading,
  sidebar,
  sidebarLeft,
  sidebarNoBox,
  t,
  title,
}) {
  const pageContext = usePageContext()

  usePageTitle(title)
  usePageBreadcrumbs(breadcrumbs)

  return (
    <div
      className={`w-full max-w-screen-xl mx-auto p-4 sm:p-6 space-y-4 lg:p-12 lg:space-y-8 ${className}`}
    >
      <HtmlHeader title={pageContext.title || title} t={t} />
      <div className="space-y-8">
        {showHeading && (
          <Heading
            className="text-4xl"
            title={pageContext.title || title}
            t={t}
          />
        )}
        <div>
          <Breadcrumbs items={pageContext.breadcrumbs} t={t} />
        </div>
      </div>
      <div
        className={`flex flex-col space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0 ${
          sidebar && sidebarLeft
            ? 'flex-col-reverse space-y-reverse lg:flex-row-reverse lg:space-x-reverse'
            : ''
        }`}
      >
        <Box
          className={`w-full ${sidebar ? 'lg:w-2/3' : ''} ${contentClass}`}
          padding="big"
          noBox={noBox}
        >
          {children}
        </Box>
        {sidebar && (
          <div className="w-full lg:w-1/3">
            <Box noBox={sidebarNoBox}>{sidebar}</Box>
          </div>
        )}
      </div>
    </div>
  )
}

Page.propTypes = {
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  contentClass: PropTypes.string,
  noBox: PropTypes.bool,
  showHeading: PropTypes.bool,
  sidebar: PropTypes.element,
  sidebarLeft: PropTypes.bool,
  sidebarNoBox: PropTypes.bool,
  t: PropTypes.func,
  title: PropTypes.string,
  transparent: PropTypes.bool,
}
Page.defaultProps = {
  className: '',
  contentClass: '',
  layout: 'content',
}

export default observer(Page)
