import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { isFunction, isString, isObject } from 'utils/types'

export { pick, omit, isEmpty, isEqual }

export const setMap = (object, { setValue = (key, value) => value } = {}) =>
  isObject(object)
    ? new Map(Object.keys(object).map(key => [key, setValue(key, object[key])]))
    : null

/**
 * Returns an object's attibute using a key string or a funciton
 * @param object item
 * @param string|function key String used as a key, or Function called to retrive item's attribute
 * @returns `any`
 */
export function getAttribute(object, key) {
  return isFunction(key)
    ? key(object)
    : isString(key) && isObject(object)
    ? object[key]
    : null
}

export function removeUndefined(object) {
  return Object.keys(object).reduce((acc, cur) => {
    if (object[cur] !== undefined) {
      acc[cur] = object[cur]
    }
    return acc
  }, {})
}
