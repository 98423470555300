import React, { lazy, Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Link } from 'react-router-dom'

import appIds from 'config/appIds'
import { useTheme } from 'config/theme'
import apiState from 'stores/ApiState'
import {
  AppProvider,
  useAppContext,
  useAppVersionCheck,
} from 'stores/AppContext'
import { AuthProvider } from 'stores/AuthContext'
import { CookiesConsentProvider } from 'stores/CookiesConsentContext'
import { EntityProvider, useEntity } from 'stores/EntityContext'
import UpdateApp from 'pages/UpdateApp'
import { MessagesProvider } from 'pages/messages/data/MessagesContext'
import { QuestionnairesProvider } from 'pages/questionnaires/data/QuestionnairesContext'
import { PageProvider } from 'ui/Page'
import CookieConsent from 'ui/CookieConsent'
import ErrorScreen from 'ui/ErrorScreen'
import LinkProvider from 'ui/LinkContext'
import Loading from 'ui/Loading'
import ErrorBoundary from 'utils/ErrorBoundary'
import I18nProvider from 'utils/I18nProvider'
import Router from './Router'

const MainLayout = lazy(() => import('ui/layouts/Main/MainLayout'))
const ServerOffline = lazy(() => import('ui/ServerOffline'))

function LinkElement({ href, ...props }) {
  return <Link to={href} {...props} />
}
LinkElement.propTypes = {
  href: PropTypes.string,
}

const AppRouter = observer(() => {
  const appState = useAppContext()
  const { siteTitle, isNativeApp } = appState
  const siteHasApp = !!appIds[window.location.hostname]
  const entity = useEntity()

  useTheme(entity?.theme)

  useEffect(() => {
    if (entity) {
      appState.setLocale(entity.language ?? 'en')
      appState.setSiteLogo(entity.logoURL)
      appState.setSiteFavicon(entity.faviconURL)
      appState.setSiteTitle(entity.siteTitle)
      appState.setSiteDescription(entity.siteDescription)
      appState.setSiteHasApp(!!appIds[window.location.hostname])
    }
  }, [appState, entity, siteTitle])

  if (entity?.name === 'Hope Kurse' || siteHasApp) {
    if (isNativeApp) {
      return <UpdateApp />
    }
    else {
      window.location.href = '/redirect'
      return null;
    }
  }

  return (
    <BrowserRouter>
      <MainLayout loading={!entity}>
        <Router />
      </MainLayout>
      {!isNativeApp && <CookieConsent />}
    </BrowserRouter>
  )
})

const AppProviders = observer(() => {
  const appState = useAppContext()
  const version = useAppVersionCheck()
  const { t } = useTranslation()

  if (apiState.offline) {
    return <ServerOffline />
  }

  if (!version) return null

  return (
    <ErrorBoundary
      fallback={<ErrorScreen t={t} />}
      reportCallback={appState.reportError}
    >
      <EntityProvider>
        <CookiesConsentProvider>
          <AuthProvider>
            <MessagesProvider>
              <QuestionnairesProvider>
                <AppRouter />
              </QuestionnairesProvider>
            </MessagesProvider>
          </AuthProvider>
        </CookiesConsentProvider>
      </EntityProvider>
    </ErrorBoundary>
  )
})

export default function App() {
  return (
    <Suspense fallback={<span />}>
      <AppProvider>
        <I18nProvider>
          <LinkProvider LinkElement={LinkElement}>
            <PageProvider>
              <AppProviders />
            </PageProvider>
          </LinkProvider>
        </I18nProvider>
      </AppProvider>
    </Suspense>
  )
}
