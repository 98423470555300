import { action, computed, decorate, observable } from 'mobx'
import jsSHA from 'jssha'

import appConf from 'config/app'

export default class ImageFile {
  constructor({
    smartCrop = null,
    focalArea,
    focalPoint,
    mode,
    width,
    height,
    ...args
  } = {}) {
    Object.assign(this, args)

    // If smartCrop is sent, set it as the focal area and detected faces
    if (smartCrop) {
      const { faces, ...rest } = smartCrop
      this.faces = faces
      this.setFocalArea(rest)
    } else if (focalArea) {
      this.setFocalArea(focalArea, focalPoint)
    }

    this.setMode(mode)
    this.setDimensions(width, height)
  }

  // Observables

  // Files
  containerId // this is the folder name inside CDN's 'acl-images' bucket, and matches file's owner entity id
  resourceName
  extension
  name
  originalFilename
  size = 0
  type

  faces = []
  exif = {}
  height = 0
  width = 0
  mode = null // 'landscape', 'panoramic', 'portrait' or 'square'
  src = null // this is used only in instances for a new or relace image

  focalArea = {
    // values in pixels
    height: 250,
    width: 250,
    x: 0,
    y: 0,
    unit: 'px',
  }
  focalPoint

  // Computed

  get aspectRatio() {
    return this.height ? this.width / this.height : 0
  }

  // Actions

  setSize = size => (this.size = size)

  setType = type => (this.type = type)

  setDimensions = (width, height) => {
    this.width = width
    this.height = height
    // Update mode
    this.setMode()
  }

  // Sets Image mode based on its dimensions.
  setMode = () => {
    this.mode =
      this.width > this.height
        ? this.width / this.height >= 2 // images with a 2:1 aspect ratio are consider panoramic
          ? 'panoramic'
          : 'landscape'
        : this.width === this.height
        ? 'square'
        : 'portrait'
  }

  setFocalArea = (focalArea, focalPoint) => {
    this.focalArea = focalArea
    this.setFocalPoint(focalPoint)
  }

  setSrc = src => (this.src = src)

  /**
   * Sets provided focalPoint (or calculates it based on focalArea)
   */
  setFocalPoint = focalPoint => {
    if (focalPoint) {
      this.focalPoint = focalPoint
      return
    }

    const { width, height, x, y } = this.focalArea

    // Calculates the focal point (center of focal area)
    const fpx = +(Math.round(width / 2) + x)
    const fpy = +(Math.round(height / 2) + y)

    this.focalPoint = `fp${fpx}x${fpy}`
  }

  // Helpers

  /**
   * Returns image's url from the CDN
   * @param {string} sizeOptions Check here for size configurations: https://github.com/willnorris/imageproxy#examples
   * @param {boolean} useFocalPoint Set as true if it should use the focal point
   */
  getUrl = (sizeOptions = '0x0', useFocalPoint = false) => {
    const { focalPoint, name, focalArea, resourceName, containerId } = this
    const { cdnUrl } = appConf.apiEndpoints

    if (!name || !resourceName || !containerId) {
      if (!resourceName || !containerId) {
        // eslint-disable-next-line no-console
        console.error("Something in missing in ImageFile's getUrl()", {
          name,
          resourceName,
          containerId,
        })
      }
      return null
    }

    // Set baseUrl
    const baseUrl = `${cdnUrl}/images/${resourceName}/${containerId}`

    // If required, add focal point parameters to size options
    if (useFocalPoint) {
      sizeOptions = `${sizeOptions},cw${focalArea.width},ch${focalArea.height},${focalPoint}`
    }

    // And url for original image
    const signature = this.getFileSignature(containerId, sizeOptions, name)

    return `${baseUrl}/${signature}/${sizeOptions}/${name}`
  }

  getFileSignature = (...args) => {
    const sha = new jsSHA('SHA-1', 'TEXT')
    sha.setHMACKey(appConf.signatureKey, 'TEXT')

    for (const arg of args) {
      sha.update(arg)
    }

    return sha.getHMAC('HEX')
  }
}

decorate(ImageFile, {
  size: observable,
  type: observable,
  exif: observable,
  faces: observable,
  focalArea: observable,
  focalPoint: observable,
  height: observable,
  mode: observable,
  src: observable,
  width: observable,

  aspectRatio: computed,

  setSize: action,
  setType: action,
  setDimensions: action,
  setFocalArea: action,
  setFocalPoint: action,
  setMode: action,
  setSrc: action,
})
