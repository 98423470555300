import { action, computed, decorate, observable } from 'mobx'

import Advisor from 'models/Advisor'
import Student from 'models/Student'

export default class MessageReceiver {
  constructor({ receiver, receiverType, ...args }) {
    Object.assign(this, args)

    this.receiverType = receiverType
    this.receiver =
      receiverType === 'Account' ? new Advisor(receiver) : new Student(receiver)
  }

  // Observables

  isDeleted = false
  readAt = null
  receiver
  receiverId
  receiverType
  updatedAt = new Date()

  // Computed

  get isRead() {
    return !!this.readAt
  }

  // Actions

  setRead = value => {
    this.updatedAt = new Date()
    this.readAt = value ? new Date() : null
  }
}

decorate(MessageReceiver, {
  isDeleted: observable,
  readAt: observable,
  receiver: observable,
  receiverId: observable,
  receiverType: observable,
  updatedAt: observable,

  isRead: computed,

  setRead: action,
})
