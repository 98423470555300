import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    // languages, namespaces, resources
    lng: 'en',
    fallbackLng: 'en',
    load: 'languageOnly',
    ns: ['common', 'form', 'menu', 'footer', 'session'],
    defaultNS: 'common',
    fallbackNS: 'common',

    // misc
    keySeparator: '.',
    nsSeparator: ':',
    pluralSeparator: '_',
    contextSeparator: '_',

    // missing keys
    saveMissing: false,

    // logging
    debug: false,

    // plugin options
    cache: {
      enabled: true,
    },

    // react-i18next options
    react: {
      useSuspense: true,
    },

    // i18next-http-backend options
    backend: {
      loadPath: '/locales/{{ns}}/{{lng}}.json',
      addPath: '/locales/{{ns}}/{{lng}}.missing.json',
    },
  })

const locales = ['de', 'en', 'es', 'fr', 'it', 'no', 'pt', 'ru', 'zh']

export { locales }

export default i18n
