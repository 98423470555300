export default {
  rootUrl: process.env.REACT_APP_API_URL,
  cdnUrl: process.env.REACT_APP_CDN_URL,
  clientToken: process.env.REACT_APP_CLIENT_TOKEN,
  sseNofitications: {
    url: process.env.REACT_APP_SSE_URL,
  },

  defaults: {
    list: {
      method: 'get',
      url: '/',
      count: 'count',
    },
    item: {
      method: 'get',
      url: '/{id}',
    },
    update: {
      method: 'patch',
      url: '/{id}',
    },
  },
  resources: {
    roles: {
      resource: 'roles',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
      },
    },
    locales: {
      resource: 'locales',
      endpoints: {},
    },
    courseStatuses: {
      resource: 'courseStatuses',
      endpoints: {
        setActiveSlide: {
          method: 'post',
          url: '/{id}/setActiveSlide',
        },
        submitQuestionnaire: {
          method: 'post',
          url: '/{id}/submitQuestionnaire',
        },
        correctQuestionnaire: {
          method: 'post',
          url: '/{id}/correctQuestionnaire',
        },
      },
    },
    questionnaireStatuses: {
      resource: 'questionnaireStatuses',
      endpoints: {},
    },
    courses: {
      resource: 'courses',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
        getBySlug: {
          method: 'get',
          url: '/slug/{slug}',
        },
        lessons: {
          method: 'get',
          url: '/{id}/lessons',
        },
        start: {
          method: 'post',
          url: '/{id}/start',
        },
      },
    },
    scopes: {
      resource: 'scopes',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
      },
    },
    lessons: {
      resource: 'lessons',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
        getBySlug: {
          method: 'get',
          url: '/slug/{slug}',
        },
        slides: {
          method: 'get',
          url: '/{id}/slides',
        },
        createSlide: {
          method: 'post',
          url: '/{id}/slides',
        },
      },
    },
    slides: {
      resource: 'slides',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
        getBySlug: {
          method: 'get',
          url: '/slug/{slug}',
        },
        contents: {
          method: 'get',
          url: '/{id}/contents',
        },
      },
    },
    questionnaires: {
      resource: 'questionnaires',
      endpoints: {
        questions: {
          method: 'get',
          url: '/{id}/questions',
        },
        createQuestion: {
          method: 'post',
          url: '/{id}/questions',
        },
      },
    },
    questions: {
      resource: 'questions',
      endpoints: {},
    },
    categories: {
      resource: 'categories',
      endpoints: {
        item: {
          method: 'get',
          url: '/{id}',
        },
      },
    },
    entities: {
      resource: 'entities',
      endpoints: {
        currentEntity: {
          method: 'get',
          url: '/currentEntity',
        },
        courses: {
          method: 'get',
          url: '/{id}/courses',
          count: 'count',
        },
        categories: {
          method: 'get',
          url: '/{id}/categories',
          count: 'count',
        },
        countries: {
          method: 'get',
          url: '/{id}/countries',
        },
      },
    },
    accounts: {
      resource: 'accounts',
      endpoints: {
        login: {
          method: 'post',
          url: '/login',
        },
        changePassword: {
          method: 'post',
          url: '/change-password',
        },
        requestPasswordReset: {
          method: 'post',
          url: '/request-password-reset',
        },
        resetPassword: {
          method: 'post',
          url: '/reset-password',
        },
        logout: {
          method: 'post',
          url: '/logout',
        },
        addRole: {
          method: 'post',
          url: '/{id}/addRole',
        },
        removeRole: {
          method: 'post',
          url: '/{id}/removeRole',
        },
        createMessage: {
          method: 'post',
          url: '/{id}/createMessage',
        },
        messageContacts: {
          method: 'get',
          url: '/{id}/messageContacts',
        },
        messages: {
          method: 'get',
          url: '/{id}/messages',
          count: 'count',
        },
        readMessage: {
          method: 'post',
          url: '/{id}/readMessage',
        },
        unreadMessage: {
          method: 'post',
          url: '/{id}/unreadMessage',
        },
        students: {
          method: 'get',
          url: '/{id}/students',
          count: 'count',
        },
        courseStatuses: {
          method: 'get',
          url: '/{id}/courseStatuses',
          count: 'count',
        },
        questionnairesToCorrect: {
          method: 'get',
          url: '/{id}/questionnairesToCorrect',
          count: 'count',
        },
        questionnaires: {
          method: 'get',
          url: '/{id}/questionnaires',
        },
        registerPush: {
          method: 'post',
          url: '/{id}/register-push-device',
        },
        unregisterPush: {
          method: 'post',
          url: '/{id}/unregister-push-device',
        },
      },
    },
    students: {
      resource: 'students',
      endpoints: {
        add: {
          method: 'post',
          url: '/',
        },
        advisors: {
          method: 'get',
          url: '/{id}/advisors',
        },
        login: {
          method: 'post',
          url: '/login',
        },
        verifyAccount: {
          method: 'get',
          url: '/confirm',
        },
        exists: {
          method: 'post',
          url: '/exists',
        },
        changePassword: {
          method: 'post',
          url: '/change-password',
        },
        requestPasswordReset: {
          method: 'post',
          url: '/request-password-reset',
        },
        requestVerificationEmail: {
          method: 'post',
          url: '/request-verification-email',
        },
        resetPassword: {
          method: 'post',
          url: '/reset-password',
        },
        logout: {
          method: 'post',
          url: '/logout',
        },
        messageContacts: {
          method: 'get',
          url: '/{id}/messageContacts',
        },
        messages: {
          method: 'get',
          url: '/{id}/messages',
          count: 'count',
        },
        createMessage: {
          method: 'post',
          url: '/{id}/createMessage',
        },
        readMessage: {
          method: 'post',
          url: '/{id}/readMessage',
        },
        unreadMessage: {
          method: 'post',
          url: '/{id}/unreadMessage',
        },
        courseStatuses: {
          method: 'get',
          url: '/{id}/courseStatuses',
          count: 'count',
        },
        registerPush: {
          method: 'post',
          url: '/{id}/register-push-device',
        },
        unregisterPush: {
          method: 'post',
          url: '/{id}/unregister-push-device',
        },
        updatePreferences: {
          method: 'post',
          url: '/{id}/update-preferences',
        },
        deleteAccount: {
          method: 'post',
          url: '/{id}/delete-account',
        },
      },
    },
    notifications: {
      resource: 'notifications',
      endpoints: {
        markAsRead: {
          method: 'post',
          url: '/{id}/read',
        },
        markAsUnread: {
          method: 'post',
          url: '/{id}/unread',
        },
      },
    },
    clients: {
      resource: 'clients',
      endpoints: {
        getVersion: {
          method: 'get',
          url: '/version',
        },
        reportError: {
          method: 'post',
          url: '/report-error',
        },
      },
    },
  },
}
