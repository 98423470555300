import { decorate, observable, action } from 'mobx'

import User from 'models/User'

export default class Contact extends User {
  constructor({ messagesToRead, lastMessage, ...args } = {}) {
    super(args)

    this.messagesToRead = messagesToRead || 0
    this.lastMessage = lastMessage || null
  }

  // Observables
  messagesToRead = 0
  lastMessage

  setMessagesToRead = value => {
    this.messagesToRead = value
  }
}

decorate(Contact, {
  messagesToRead: observable,
  lastMessage: observable,

  setMessagesToRead: action,
})
