import { action, computed, decorate, observable } from 'mobx'

import Course from 'pages/courses/data/Course'
import Student from 'models/Student'
import User from 'models/User'

import { arrayOf, isArray } from 'utils/arrays'

export default class Advisor extends User {
  constructor({ absenceMessage, welcomeMessage, students, ...args } = {}) {
    super(args)

    this.absenceMessage = absenceMessage || {}
    this.welcomeMessage = welcomeMessage

    this.setStudents(students)
  }

  // Observables

  absenceMessage
  welcomeMessage

  mainAdvisor
  students
  courses = []

  // Computed

  get absent() {
    if (!this.absenceMessage) return false

    const { from, isEnabled, message, to } = this.absenceMessage

    if (!from && !to) return false

    const [fromYear, fromMonth, fromDay] = from.split('-')
    const [toYear, toMonth, toDay] = to.split('-')

    const dateFrom = new Date(
      fromYear,
      parseInt(fromMonth) - 1,
      parseInt(fromDay)
    )
    const dateTo = new Date(toYear, parseInt(toMonth) - 1, parseInt(toDay) + 1)
    const now = new Date()

    return isEnabled && message && dateFrom <= now && dateTo > now
  }

  // Actions

  addCourse = course => {
    if (!isArray(this.courses)) this.courses = []
    this.courses.push(new Course(course))
  }

  setStudents = students => {
    this.students = arrayOf({ model: Student, withItems: students })
  }

  update = ({ absenceMessage, welcomeMessage, ...args } = {}) => {
    super.update(args)
    this.absenceMessage = absenceMessage
    this.welcomeMessage = welcomeMessage
  }

  // Overrides

  isVerified() {
    return true
  }
}

decorate(Advisor, {
  absenceMessage: observable,
  courses: observable,
  mainAdvisor: observable,
  students: observable,
  welcomMessage: observable,

  absent: computed,

  addCourse: action,
  setStudents: action,
  update: action,
})
