const root = document.documentElement

function cleanString(string) {
  return string.trim().replace(/['"]/gm, '')
}

function setCssVariable(variable, value) {
  root.style.setProperty(`--${variable}`, value)
}

function setColorsVariables(variant = '', colors = '') {
  const themeColors = colors.replace(/(\r\n|\n|\r|{|})/gm, '').split(',')

  for (const themeColor of themeColors) {
    const [key, color] = themeColor.split(':')

    if (key && color) {
      setCssVariable(`${variant}-${cleanString(key)}`, cleanString(color))
    }
  }
}

export function useTheme(theme) {
  if (!theme) return

  const { colors, font } = theme
  if (font) setCssVariable('font', font)

  const { primary, secondary, grayscale } = colors || {}

  if (primary) setColorsVariables('primary', primary)
  if (secondary) setColorsVariables('secondary', secondary)
  if (grayscale) setColorsVariables('grayscale', grayscale)
}
