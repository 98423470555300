import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { observer, useLocalStore } from 'mobx-react'

import { useAuthContext } from 'stores/AuthContext'
import QuestionnairesStore from './QuestionnairesStore'

const QuestionnairesContext = React.createContext()

export const QuestionnairesProvider = observer(({ children }) => {
  const { user } = useAuthContext()
  const store = useLocalStore(() => new QuestionnairesStore())

  useEffect(() => {
    if (user) {
      store.sseSubscribe(user)
    } else {
      store.sseUnsubscribe()
    }
  }, [store, user])

  return (
    <QuestionnairesContext.Provider value={user ? store : {}}>
      <QuestionnairesCountLoader>{children}</QuestionnairesCountLoader>
    </QuestionnairesContext.Provider>
  )
})

QuestionnairesProvider.propTypes = {
  children: PropTypes.node,
}

function QuestionnairesCountLoader({ children }) {
  useQuestionnaireStatusesCount()
  return children
}

export function useQuestionnairesContext() {
  return useContext(QuestionnairesContext)
}

export function useQuestionnaireStatusesCount() {
  const { user } = useAuthContext()
  const questionnairesStore = useQuestionnairesContext()

  useEffect(() => {
    if (!user?.isAdvisor || questionnairesStore.loading) return
    questionnairesStore.countQuestionnairesToCorrect(user)
  }, [questionnairesStore, user])

  return questionnairesStore
}

export function useQuestionnaireStatuses(type = 'pending') {
  const { user } = useAuthContext()
  const questionnairesStore = useQuestionnairesContext()
  const { page, pageSize, sortOrder, sortField } = questionnairesStore

  useEffect(() => {
    if (!user?.isAdvisor) return
    questionnairesStore.loadQuestionnaireStatuses({ user, type })
  }, [questionnairesStore, type, user, page, pageSize, sortOrder, sortField])

  return questionnairesStore
}

export function useQuestionnaireStatus(id) {
  const questionnairesStore = useQuestionnairesContext()

  useEffect(() => {
    questionnairesStore.loadQuestionnaireStatus({ id })
  }, [questionnairesStore, id])

  return questionnairesStore
}

export function useCourseStatusById(id) {
  const questionnairesStore = useQuestionnairesContext()

  useEffect(() => {
    questionnairesStore.loadCourseStatusById({ id })
  }, [questionnairesStore, id])

  return questionnairesStore
}
