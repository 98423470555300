import { action, computed, decorate, observable } from 'mobx'

import Student from 'models/Student'
import Answer from './Answer'
import CourseStatus from './CourseStatus'

import { arrayOf, isArray } from 'utils/arrays'

export default class QuestionnaireStatus {
  constructor({ answers, courseStatus, questionnaire, student, ...args }) {
    Object.assign(this, args)

    this.answers = arrayOf({
      model: Answer,
      withItems: answers,
    })
    this.courseStatus = courseStatus && new CourseStatus(courseStatus)
    this.student = student && new Student(student)
    this.questionnaire = questionnaire
  }

  // Observables

  id = null
  isDeleted = false
  isEnabled = true
  createdAt = new Date()
  updatedAt = new Date()

  answers = []
  savedAt
  submittedAt
  correctedAt

  // Computed

  get ready() {
    return this.submittedAt && this.correctedAt
  }

  // Actions

  update = ({ answers, correctedAt, savedAt, submittedAt }) => {
    if (correctedAt) this.correctedAt = correctedAt
    if (savedAt) this.savedAt = savedAt
    if (submittedAt) this.submittedAt = submittedAt
    if (isArray(answers)) {
      this.answers = arrayOf({
        model: Answer,
        withItems: answers,
      })
    }
  }
}

decorate(QuestionnaireStatus, {
  id: observable,
  isEnabled: observable,
  isDeleted: observable,
  createdAt: observable,
  updatedAt: observable,

  answers: observable,
  savedAt: observable,
  submittedAt: observable,
  correctedAt: observable,

  ready: computed,

  update: action,
})
