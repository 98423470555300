import { action, computed, decorate, observable } from 'mobx'

import appConf from 'config/app'
import Student from 'models/Student'
import Advisor from 'models/Advisor'
import ApiStore from 'stores/ApiStore'
import { setAuthToken } from 'utils/fetchData'
import { isFunction } from 'utils/types'

const sessionKey = 'UserSession'
const sessionTypeKey = 'SessionType'
const entityIdKey = 'EntityId'

const { sseNofitications } = appConf.apiEndpoints

export default class AuthStore extends ApiStore {
  // Observables

  authToken = null
  initialLoading = true
  rolesLoaded = false
  session = null
  user = null

  currentEntity
  selectedEntityId

  sse
  isReconnect = false

  constructor() {
    super({
      model: Student,
      defaultResource: 'students',
    })
  }

  initStore = type => {
    this.resource = type === 'account' ? 'accounts' : 'students'
    this.model = type === 'account' ? Advisor : Student
    this.storeSessionType(type)
  }

  // SSE (Server Send Events)

  subscribe = () => {
    const { userId } = this.session || {}
    if (!userId) return

    this.isReconnect = false
    this.sse = new EventSource(`${sseNofitications.url}/${userId}`)

    this.sse.addEventListener(
      'open',
      () => {
        if (this.isReconnect && this.session) {
          this.loadProfile()
        }
      },
      false
    )

    // When we got disconnected from the sse
    this.sse.addEventListener(
      'error',
      e => {
        if (e.target.readyState === EventSource.CLOSED) {
          // TODO: add onClose
        } else if (e.target.readyState === EventSource.CONNECTING) {
          this.isReconnect = true
        }
      },
      false
    )

    // Account disabled
    this.sse.addEventListener(
      'ACCOUNT_DISABLED',
      () => this.setSession(null),
      false
    )

    // Account updated
    this.sse.addEventListener(
      'ACCOUNT_UPDATED',
      () => this.loadProfile({ onlyProfile: true }),
      false
    )

    // Permissions updated
    this.sse.addEventListener(
      'PERMISSIONS_UPDATED',
      () => this.loadProfile(),
      false
    )
  }

  unsubscribe = () => {
    if (this.sse) this.sse.close()
  }

  // Computed

  get authenticated() {
    return this.session && this.user
  }

  get dataIsLoading() {
    return this.initialLoading
  }

  // Actions

  setUser = (data, onlyProfile) => {
    if (this.user && data) {
      this.user.update(data)
    } else {
      this.user = data ? new this.model(data) : null
    }

    // Set user's default entity, unless only the profile is loaded
    if (this.user && !onlyProfile) {
      this.setRoles(data.roles)
      this.setRolesLoaded(true)
    }
  }

  setRoles = data => {
    if (this.user) this.user.setRoles(data)
  }

  setRolesLoaded = loaded => {
    this.rolesLoaded = loaded
  }

  // NOTE: method useless for the AMS Course Manager, but necessary to compile ui components of ECL
  setSelectedEntityId = () => null

  setSession = session => {
    if (!session && this.session) {
      // Unsubscribe from sse notifications for the user
      this.unsubscribe()

      // Unregister device from push notifications
      const pushInfo = this.getPushInfo()
      if (pushInfo) {
        this.apiCall({
          endpoint: 'unregisterPush',
          params: {
            id: this.session.userId,
          },
          data: pushInfo,
        })
      }
    }

    this.setSessionData(session)

    this.setAuthToken(session ? session.id : null)
    this.storeSession(session)

    if (session) {
      this.loadProfile()
      // Subscribe to sse notifications for the user
      this.subscribe({
        userId: session.userId,
      })

      // Register device for push notifications
      const pushInfo = this.getPushInfo()
      if (pushInfo) {
        this.apiCall({
          endpoint: 'registerPush',
          params: {
            id: session.userId,
          },
          data: pushInfo,
        })
      }
    } else {
      this.setUser(null)
      this.setRolesLoaded(false)
    }
  }

  setSessionData = session => {
    this.session = session
  }

  setAuthToken = token => {
    this.authToken = setAuthToken(token)
  }

  setInitialLoading = loading => {
    this.initialLoading = loading
  }

  // Account:
  // --------

  verifyAccount = ({ uid, token, onSuccess, onError } = {}) => {
    this.apiCall({
      resource: 'students',
      endpoint: 'verifyAccount',
      query: {
        uid,
        token,
      },
      onSuccess,
      onError,
    })
  }

  // Profile:
  // --------

  loadProfile = ({ onlyProfile, onSuccess, onError } = {}) => {
    if (this.session && this.session.userId) {
      this.apiCall({
        endpoint: 'item',
        params: {
          id: this.session.userId,
        },
        query: {
          filter: { include: ['roles'] },
        },
        onSuccess: data => {
          this.setUser(data, onlyProfile)
          isFunction(onSuccess) && onSuccess(this.user)
        },
        onError,
      })
    }
  }

  updateProfile = ({ onSuccess, onError, data, ...rest } = {}) => {
    this.apiCall({
      endpoint: 'update',
      params: {
        id: this.session.userId,
      },
      data,
      onSuccess: () => {
        this.loadProfile({ onlyProfile: true, onSuccess, onError })
      },
      onError,
      ...rest,
    })
  }

  updatePreferences = ({ onSuccess, onError, data, ...rest } = {}) => {
    this.apiCall({
      endpoint: 'updatePreferences',
      params: {
        id: this.session.userId,
      },
      data,
      onSuccess: () => {
        this.loadProfile({ onlyProfile: true, onSuccess, onError })
      },
      onError,
      ...rest,
    })
  }

  deleteAccount = ({ onSuccess, onError, data, ...rest } = {}) => {
    this.apiCall({
      endpoint: 'deleteAccount',
      params: {
        id: this.session.userId,
      },
      data,
      onSuccess: () => {
        // This timeout is a to give time to the redirect before deleting the session
        // Not ideal, but it works
        setTimeout(() => {
          this.setSession(null)
        }, 100)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
      ...rest,
    })
  }

  // Session:
  // --------

  login = ({ email, password, onSuccess, onError, type }) => {
    this.setInitialLoading(true)
    this.initStore(type)
    this.apiCall({
      endpoint: 'login',
      data: {
        email,
        password,
      },
      onSuccess: session => {
        this.setSession(session)
        onSuccess && onSuccess()
      },
      onError,
      onFinish: () => {
        this.setInitialLoading(false)
      },
    })
  }

  logout = ({ onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'logout',
      onSuccess: () => {
        this.setSession(null)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
    })
  }

  updateSession = ({ error, datetime }) => {
    switch (error) {
      // If there is an error related to an invalid session or
      // an unauthorized access, the session will we nullified.
      case 'AUTHORIZATION_REQUIRED':
      case 'INVALID_TOKEN': {
        this.setSession(null)
        break
      }
      case 'SERVER_OFFLINE': {
        // TODO: inform that sever is offline
        break
      }
      default: {
        const { session } = this
        if (session) {
          // Update session's creation date to extend its duration.
          session.created = datetime
        }
        this.setSessionData(session)
        break
      }
    }
  }

  registerStudent = ({ onError, onSuccess, redirect, roleId, ...data }) => {
    this.apiCall({
      resource: 'students',
      endpoint: 'add',
      data,
      query: {
        redirect,
        roleId,
      },
      onSuccess: data => {
        onSuccess && onSuccess(data)
      },
      onError,
    })
  }

  // Change password:
  // ----------------

  changePassword = ({
    currentPassword,
    newPassword,
    token,
    onSuccess,
    onError,
  } = {}) => {
    this.apiCall({
      endpoint: 'changePassword',
      params: {
        id: this.session.userId,
      },
      data: {
        oldPassword: currentPassword,
        newPassword: newPassword,
        token,
      },
      onSuccess,
      onError,
    })
  }

  // Set new password:
  // ----------------

  setPassword = ({ password, token, type, onSuccess, onError } = {}) => {
    this.setAuthToken(token)

    this.apiCall({
      resource: type === 'account' ? 'accounts' : 'students',
      endpoint: 'resetPassword',
      data: {
        newPassword: password,
      },
      onSuccess: () => {
        this.setAuthToken(null)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
    })
  }

  // Request password reset:
  // ----------------
  requestPasswordReset = ({ email, type, onSuccess, onError } = {}) => {
    this.apiCall({
      resource: type === 'account' ? 'accounts' : 'students',
      endpoint: 'requestPasswordReset',
      data: { email },
      onSuccess,
      onError,
    })
  }

  // Request Account Verification E-mail:
  // ----------------
  requestVerificationEmail = ({ email, onSuccess, onError } = {}) => {
    this.apiCall({
      resource: 'students',
      endpoint: 'requestVerificationEmail',
      data: { email },
      onSuccess,
      onError,
    })
  }

  // Change Email:
  // ----------------

  changeEmail = ({ newEmail, password, token, onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'changeEmail',
      params: {
        id: this.session.userId,
      },
      data: {
        newEmail,
        password,
        token,
      },
      onSuccess,
      onError,
    })
  }

  verifyNewEmail = ({ uid, token, onSuccess, onError } = {}) => {
    this.apiCall({
      endpoint: 'verifyNewEmail',
      query: {
        uid,
        token,
      },
      onSuccess,
      onError,
    })
  }

  checkIfUserExists = ({ username, email } = {}) => {
    return new Promise((resolve, reject) => {
      this.apiCall({
        resource: 'students',
        endpoint: 'exists',
        data: { username, email },
        onSuccess: response => resolve(response),
        onError: error => reject(error),
      })
    })
  }

  // Change Avatar:
  // ----------------

  changeAvatar = ({
    file,
    crop,
    onUploadProgress,
    onSuccess,
    onError,
  } = {}) => {
    this.apiCall({
      resource: 'avatars',
      endpoint: 'upload',
      params: { id: this.session.userId },
      data: { file, crop: JSON.stringify(crop) },
      uploadFiles: true,
      onUploadProgress,
      onSuccess,
      onError,
    })
  }

  // Local storage:
  // --------------

  storeSession = session => {
    localStorage.setItem(sessionKey, session && JSON.stringify(session))
  }

  retrieveSession = async () => {
    this.initStore(await localStorage.getItem(sessionTypeKey))
    this.setSession(await JSON.parse(await localStorage.getItem(sessionKey)))
  }

  storeSessionType = type => localStorage.setItem(sessionTypeKey, type)

  storeEntityId = entityId => localStorage.setItem(entityIdKey, entityId)

  getEntityId = () => localStorage.getItem(entityIdKey)

  getPushInfo = () => JSON.parse(localStorage.getItem('pushInfo') || null)
}

decorate(AuthStore, {
  authToken: observable,
  currentEntity: observable,
  entities: observable,
  initialLoading: observable,
  pendingOperations: observable,
  rolesLoaded: observable,
  selectedEntityId: observable,
  session: observable,
  user: observable,

  authenticated: computed,
  dataIsLoading: computed,

  setAuthToken: action,
  setInitialLoading: action,
  setRolesLoaded: action,
  setSession: action,
  setSessionData: action,
  setUser: action,
})
