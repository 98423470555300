import React, { useContext } from 'react'
import PropTypes from 'prop-types'

export const LinkWrapperContext = React.createContext()
export const LinkElementContext = React.createContext()

export default function LinkProvider({ children, LinkWrapper, LinkElement }) {
  return (
    <LinkWrapperContext.Provider value={LinkWrapper}>
      <LinkElementContext.Provider value={LinkElement}>
        {children}
      </LinkElementContext.Provider>
    </LinkWrapperContext.Provider>
  )
}
LinkProvider.propTypes = {
  children: PropTypes.node,
  LinkWrapper: PropTypes.func,
  LinkElement: PropTypes.func,
}

export function useLinkWrapper() {
  return useContext(LinkWrapperContext)
}
export function useLinkElement() {
  return useContext(LinkElementContext)
}
