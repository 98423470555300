import { action, computed, decorate, observable } from 'mobx'
import merge from 'deepmerge'

import appState from 'stores/ApiState'
import fetchData, { setClientToken } from 'utils/fetchData'
import { isFunction, isObject } from 'utils/types'
import { replaceParams } from 'utils/urls'

import appConf from 'config/app'

const { apiEndpoints } = appConf
const { clientToken } = apiEndpoints

function getRequest(resource, endpoint, params) {
  const { defaults, resources, rootUrl } = apiEndpoints
  const { resource: resourceName, endpoints } = resources[resource] || {}

  const { url, method, count } = endpoints[endpoint] || defaults[endpoint] || {}

  if (!url) return null

  const requestParams = replaceParams(url, params)
  const requestUrl = `${rootUrl}${resourceName}${requestParams}`
  const requestCountUrl = count
    ? `${requestUrl.replace(/\/$/, '')}/${count}`
    : null

  return {
    requestUrl,
    requestCountUrl,
    method,
  }
}

export async function apiCall({
  resource,
  endpoint,
  data,
  params,
  query,
  headers,
  getCount = false,
  uploadFiles,
  onUploadProgress,
  onSuccess,
  onLoading,
  onError,
  onFinish,
}) {
  const request = getRequest(resource, endpoint, params)

  if (request) {
    const requestUrl = getCount ? request.requestCountUrl : request.requestUrl
    // console.log(requestUrl)

    // sets request client token (only if differs or is null)
    setClientToken(clientToken)

    const response = await fetchData(requestUrl, {
      loading: onLoading,
      method: request.method,
      data,
      params: query,
      headers,
      uploadFiles,
      onUploadProgress,
    })

    if (response.error) {
      // eslint-disable-next-line no-console
      console.error({
        requestUrl: requestUrl,
        request,
        error: response.error,
      })
      if (isFunction(onError)) onError(response)
    } else {
      if (isFunction(onSuccess)) onSuccess(response)
    }

    // Check for errors and update session time (keep alive).
    appState.setLastApiCall(response.error ? response.error : null)

    if (isFunction(onFinish)) onFinish(response)
  } else {
    const error = {
      name: 'no-endpoint',
      resource,
      endpoint,
      params,
    }
    console.error(error) // eslint-disable-line no-console
    if (isFunction(onError)) onError({ error })
    if (isFunction(onFinish)) onFinish({ error })
  }
}

export default class ApiStore {
  constructor({
    model,
    defaultResource,
    listEndpoint = 'list',
    itemEndpoint = 'item',
    defaultList = null,
    config = appConf.apiEndpoints,
  } = {}) {
    this.model = model
    this.config = config
    this.resource = defaultResource
    this.listEndpoint = listEndpoint
    this.itemEndpoint = itemEndpoint
    this.defaultList = defaultList || defaultResource
  }

  // Default filters (override when necessary)
  filters = {
    active: { where: { isDeleted: false, isEnabled: true } },
    disabled: { where: { isDeleted: false, isEnabled: false } },
    deleted: { where: { isDeleted: true } },
    all: {},
  }

  // Observables
  filter = null // string or object
  sortField = null
  sortOrder = 'ASC'
  search = null // string
  loading = false
  total = 0
  filterTotal = 0
  page = 1
  pageSize = 10
  message = null
  error = null
  success = null

  // Computed values:
  get totalLoaded() {
    return this[this.defaultList] ? this[this.defaultList].length : 0
  }

  get canLoadMore() {
    return this.total > 0 && this.totalLoaded < this.total
  }

  // Actions:
  setLoading = value => {
    this.loading = !!value
    return this
  }

  setTotal = count => {
    this.total = count
    return this
  }

  setSearch = search => {
    this.search = search
    this.setPage(1)
    return this
  }

  setFilter = filter => {
    this.filter = filter
    this.setPage(1)
    return this
  }

  setSort = (field, isAscend = true) => {
    this.sortField = field
    this.sortOrder = isAscend ? 'ASC' : 'DESC'
    return this
  }

  setFilterTotal = count => {
    this.filterTotal = parseInt(count)
    return this
  }

  setPage = page => {
    this.page = parseInt(page)
    return this
  }

  setPageSize = pageSize => {
    this.pageSize = parseInt(pageSize)
    this.setPage(1)
    return this
  }

  setPagination = (page, pageSize) => {
    this.setPage(page)
    this.setPageSize(pageSize)
    return this
  }

  setError = error => {
    this.error = error
    return this
  }

  setMessage = message => {
    this.message = message
    return this
  }

  apiCall = async ({
    resource,
    endpoint,
    onError,
    onFinish,
    onSuccess,
    ...args
  }) => {
    apiCall({
      resource: resource || this.resource,
      endpoint,
      onLoading: this.setLoading,
      onError: ({ error }) => {
        if (isFunction(onError)) onError(error)
        this.setError(error)
      },
      onFinish,
      onSuccess,
      ...args,
    })
  }

  getList = ({
    resource,
    endpoint,
    query: originalQuery,
    searchFields = [],
    usePagination = false,
    useSort = false,
    getTotal,
    onSuccess,
    ...rest
  } = {}) => {
    const searchWhere = {}

    // If a search is required
    if (this.search && searchFields.length > 0) {
      // build soft search
      const softSearch = { like: this.search, options: 'i' }

      // add 'or' to where to search in these fields
      searchWhere.or = [
        ...searchFields.map(field => ({
          [field]: softSearch,
        })),
      ]
    }

    // Sets filter
    const filter = merge(
      this.filter
        ? isObject(this.filter)
          ? this.filter
          : this.filters[this.filter]
        : {},
      originalQuery?.filter || {}
    )

    if (searchWhere.or) {
      filter.where = {
        ...filter.where,
        ...searchWhere,
      }
    }

    // Set pagination filters when usePagination is enabled
    if (usePagination) {
      filter.limit = this.pageSize
      filter.offset = this.pageSize * (this.page - 1)
    }

    // Set sorting when useSort is enabled
    if (useSort) {
      filter.order =
        this.sortField && this.sortOrder
          ? `${this.sortField} ${this.sortOrder}`
          : undefined
    }

    // Build the query object
    const query = {
      ...originalQuery,
      filter,
    }

    // Reset and get total if required
    if (getTotal) {
      this.setTotal(0)
      this.getTotal({
        resource,
        endpoint,
        query,
        ...rest,
      })
    }

    // Get the list
    this.apiCall({
      resource,
      endpoint: endpoint || this.listEndpoint,
      query,
      ...rest,
      onSuccess: data => {
        this.setFilterTotal(data.length)

        if (isFunction(onSuccess)) onSuccess(data)
      },
    })
  }

  getTotal = ({
    resource,
    endpoint,
    onSuccess,
    query,
    params,
    setTotal = true,
  } = {}) => {
    const where = query && query.filter ? query.filter.where : null

    this.apiCall({
      resource,
      endpoint: endpoint || this.listEndpoint,
      params,
      query: { where },
      getCount: true,
      onSuccess: data => {
        if (setTotal) this.setTotal(data.count)

        if (isFunction(onSuccess)) onSuccess(data.count)
      },
    })
  }

  getItem = ({
    id,
    resource,
    endpoint,
    query,
    onSuccess,
    onError,
    raw = false,
  } = {}) => {
    this.apiCall({
      resource,
      endpoint: endpoint || this.itemEndpoint,
      params: { id },
      query,
      onSuccess: data => {
        if (isFunction(onSuccess)) {
          onSuccess(
            !raw && isFunction(this.model) ? new this.model(data) : data
          )
        }
      },
      onError,
    })
  }
}

decorate(ApiStore, {
  error: observable,
  filter: observable,
  filterTotal: observable,
  loading: observable,
  message: observable,
  page: observable,
  pageSize: observable,
  search: observable,
  sortField: observable,
  sortOrder: observable,
  success: observable,
  total: observable,

  canLoadMore: computed,
  totalLoaded: computed,

  setError: action,
  setFilter: action,
  setFilterTotal: action,
  setLoading: action,
  setMessage: action,
  setPage: action,
  setPageSize: action,
  setSearch: action,
  setSort: action,
  setTotal: action,
})
