import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { observer, useLocalStore } from 'mobx-react'
import { useHistory, useLocation } from 'react-router-dom'

import apiState from 'stores/ApiState'
import { useAppContext } from './AppContext'
import AuthStore from './AuthStore'
import { useLocationParams } from 'utils/urls'

const AuthContext = React.createContext()

export const AuthProvider = observer(({ children }) => {
  const { lastApiCall, lastApiError } = apiState

  // Init store
  const store = useLocalStore(() => new AuthStore())

  // Get user session
  useEffect(() => {
    store.retrieveSession()
  }, [store])

  // Update Session's date (ttl) or error
  useEffect(() => {
    store.updateSession({
      datetime: lastApiCall,
      error: lastApiError,
    })
  }, [store, lastApiCall, lastApiError])

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
})

AuthProvider.propTypes = {
  children: PropTypes.node,
}

export function useAuthContext() {
  return useContext(AuthContext)
}

export function useAuthUser() {
  const authStore = useAuthContext()
  return authStore.user
}

/**
 * Redirects if there is no user authenticated
 * @param {string} type one of `advisor`, `student` or `user` (default: `user`).
 * @param {string} redirectUrl default redirect url (default: `/login`)
 */
export function useRedirectUnlessUser(type = 'user', redirectUrl = '/login') {
  const appState = useAppContext()
  const { user, session, loading } = useAuthContext()
  const location = useLocation()
  const history = useHistory()

  // The defined redirect url priority: 1) appState, and 2) given by parameter
  const url = appState.redirectUrl || redirectUrl

  const hasAccess = user
    ? type === 'advisor'
      ? user.isAdvisor
      : type === 'student'
      ? user.isStudent
      : true
    : false

  useEffect(() => {
    // Wait for session to load...
    if (!loading) {
      // If there is no session...
      if (!session) {
        // ... save redirect url (to redirect after user's login)
        appState.setRedirectUrl(location.pathname)
        // ... and redirect to the defined url (an intermediate url, like /login)
        history.push(url)
      } else if (user && !hasAccess) {
        // ... or only redirect if user has no access to this url
        history.push(url)
      }
    }
  }, [appState, hasAccess, history, loading, location, session, url, user])

  return hasAccess
}

/**
 * Redirects if there is an user authenticated
 * @param {string} redirectUrl default redirect url
 */
export function useRedirectWhenUser(redirectUrl = '/courses') {
  const appState = useAppContext()
  const { user, session, loading } = useAuthContext()
  const history = useHistory()
  const { redirect } = useLocationParams()

  useEffect(() => {
    // When redirect paramater is present,
    if (redirect) {
      // set appState's redirectUrl with it.
      appState.setRedirectUrl(redirect)
    }

    // When user is logged
    if (!loading && user && session) {
      const url =
        appState.redirectUrl ||
        (user.isAdvisor ? '/questionnaires' : '/courses')

      // If appState.redirectUrl is set,
      if (appState.redirectUrl) {
        // clear it so will no longer be used.
        appState.setRedirectUrl(null)
      }

      // Redirect to the defined url
      history.push(url)
    }
  }, [appState, history, loading, redirectUrl, redirect, session, user])

  return !!user
}

/**
 * Redirects unless there a student is authenticated
 * @param {string} redirectUrl
 */
export function useRedirectUnlessStudent(redirectUrl = '/') {
  return useRedirectUnlessUser('student', redirectUrl)
}

/**
 * Redirects unless there an advisor is authenticated
 * @param {string} redirectUrl
 */
export function useRedirectUnlessAdvisor(redirectUrl = '/') {
  return useRedirectUnlessUser('advisor', redirectUrl)
}
