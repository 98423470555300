import React, { lazy, useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { observer, useLocalStore } from 'mobx-react'

import EntityStore from 'stores/EntityStore'
import { useSelectOptions } from 'utils/arrays'
import { useCountriesOptions } from 'utils/countries'

const PageLoading = lazy(() => import('ui/PageLoading'))

const EntityContext = React.createContext()

export const EntityProvider = observer(({ children }) => {
  // Init store
  const store = useLocalStore(() => new EntityStore())

  useEffect(() => {
    store.loadEntity({
      onSuccess: () => {
        store.loadEntityCountries()
      },
    })
  }, [store])

  // Wait for entity
  if (!store.entity || !store.entityCountries) return <PageLoading />

  return (
    <EntityContext.Provider value={store}>{children}</EntityContext.Provider>
  )
})

EntityProvider.propTypes = {
  children: PropTypes.node,
}

export function useEntityContext() {
  return useContext(EntityContext)
}

export function useEntity() {
  const { entity } = useEntityContext()
  return entity
}

export function useEntityField(field) {
  const entityStore = useEntityContext()
  const { entity, loading } = entityStore

  useEffect(() => {
    if (field) {
      entityStore.loadEntity({ field })
    }
  }, [entityStore, field])

  return { entity, loading }
}

export function useEntityCountries(showOthers = true) {
  const entityStore = useEntityContext()
  const { entityCountry, entityCountries, countryRegions } = entityStore

  const entityCountriesCode = entityCountries.map(
    ({ countryCode }) => countryCode
  )

  const entityCountriesOptions = useCountriesOptions(code =>
    entityCountriesCode.includes(code)
  )

  const otherCountriesOptions = useCountriesOptions(
    code => !entityCountriesCode.includes(code)
  )

  const countryRegionsOptions = useSelectOptions(countryRegions, {
    label: item => item.config.name,
    value: item => item.id,
  })

  const onCountryChange = useCallback(
    entityCountryCode => {
      const _entityCountry = entityCountries.find(
        ({ countryCode }) => countryCode === entityCountryCode
      )

      entityStore.setEntityCountry(_entityCountry)
    },
    [entityStore, entityCountries]
  )

  const countriesOptions = showOthers
    ? [
        ...entityCountriesOptions,
        { text: '-', disabled: true },
        ...otherCountriesOptions,
      ]
    : entityCountriesOptions

  return {
    entityStore,
    countriesOptions,
    scopeType: entityCountry?.scopeType,
    countryRegionsOptions,
    onCountryChange,
  }
}
