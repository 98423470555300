import { action, decorate, observable } from 'mobx'

import appConf from 'config/app'
import CourseStatus from 'pages/courses/data/CourseStatus'
import QuestionnaireStatus from 'pages/courses/data/QuestionnaireStatus'
import ApiStore from 'stores/ApiStore'
import { arrayOf } from 'utils/arrays'

const { sseNofitications } = appConf.apiEndpoints

export default class QuestionnairesStore extends ApiStore {
  constructor() {
    super({
      model: QuestionnaireStatus,
      defaultResource: 'questionnaireStatuses',
    })

    this.sortField = 'submittedAt'
    this.sortOrder = 'ASC'
  }

  // Observables

  questionnaireStatuses = []
  questionnaireStatusesCount = 0
  questionnaireStatusesType = ''
  questionnairesToCorrectCount = 0
  loadingQuestionnaireStatuses = false
  courseStatus = null
  questionnaireStatus = null

  sse = null

  // SSE (Server Send Events)

  sseSubscribe = user => {
    if (!user) return
    this.isReconnect = false
    this.sse = new EventSource(`${sseNofitications.url}/${user.id}`)

    // Questionnaires
    this.sse.addEventListener(
      'QUESTIONNAIRE_SUBMITTED',
      ({ data }) => {
        const { courseId } = JSON.parse(data) // eslint-disable-line no-unused-vars
        if (user.isAdvisor) {
          this.countQuestionnairesToCorrect(user)
        }
      },
      false
    )

    this.sse.addEventListener(
      'QUESTIONNAIRE_CORRECTED',
      ({ data }) => {
        const { courseId } = JSON.parse(data) // eslint-disable-line no-unused-vars
        if (user.isAdvisor) {
          this.countQuestionnairesToCorrect(user)
        }
      },
      false
    )
  }

  sseUnsubscribe = () => {
    if (this.sse) this.sse.close()
  }

  // Actions

  setQuestionnaireStatuses = questionnaireStatuses => {
    this.questionnaireStatuses = arrayOf({
      model: QuestionnaireStatus,
      withItems: questionnaireStatuses,
    })
  }

  setCourseStatus = data => {
    this.courseStatus = data && new CourseStatus(data)
  }

  setQuestionnaireStatus = data => {
    this.questionnaireStatus = data && new QuestionnaireStatus(data)
  }

  setQuestionnaireStatusesCount = value => {
    this.questionnaireStatusesCount = value
  }

  setQuestionnaireStatusesType = value => {
    this.questionnaireStatusesType = value
  }

  setQuestionnairesToCorrectCount = value => {
    this.questionnairesToCorrectCount = value
  }

  setLoadingQuestionnaireStatuses = value => {
    this.loadingQuestionnaireStatuses = value
  }

  // Others

  loadCourseStatusById = ({ id, onSuccess }) => {
    this.getItem({
      id,
      raw: true,
      resource: 'courseStatuses',
      query: {
        filter: {
          include: [
            {
              relation: 'student',
              scope: {
                // fields: ['id', 'username', 'firstName', 'lastName'],
              },
            },
            {
              relation: 'questionnaireStatuses',
              scope: {
                // fields: ['id', 'title', 'slug', 'position'],
              },
            },
            {
              relation: 'reachedLesson',
              scope: {
                fields: ['id', 'title', 'slug', 'position'],
              },
            },
            {
              relation: 'activeLesson',
              scope: {
                fields: ['id', 'title', 'slug', 'position'],
              },
            },
            {
              relation: 'activeSlide',
              scope: {
                fields: ['id', 'title', 'slug', 'position', 'lessonId', 'type'],
              },
            },
            {
              relation: 'course',
              scope: {
                include: {
                  relation: 'lessons',
                  scope: {
                    fields: [
                      'id',
                      'title',
                      'slug',
                      'title',
                      'position',
                      'isDeleted',
                      'isEnabled',
                    ],
                    where: {
                      isDeleted: false,
                      isEnabled: true,
                    },
                    order: 'position ASC',
                    include: {
                      relation: 'slides',
                      scope: {
                        fields: [
                          'id',
                          'title',
                          'position',
                          'lessonId',
                          'slug',
                          'type',
                          'isDeleted',
                          'isEnabled',
                        ],
                        where: {
                          type: 'questionnaire',
                          isDeleted: false,
                          isEnabled: true,
                        },
                        order: 'position ASC',
                        include: [
                          {
                            relation: 'questionnaire',
                            scope: {
                              fields: ['id', 'title', 'requiresRevision'],
                            },
                          },
                        ],
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      },
      onSuccess: data => {
        this.setCourseStatus(data)
        onSuccess && onSuccess(this.courseStatus)
      },
    })
  }

  loadQuestionnaireStatus = ({ id, onSuccess }) => {
    this.getItem({
      id,
      resource: 'questionnaireStatuses',
      raw: true,
      query: {
        filter: {
          include: [
            'answers',
            {
              relation: 'student',
              scope: {
                fields: ['id', 'firstName', 'lastName', 'username'],
              },
            },
            {
              relation: 'questionnaire',
              scope: {
                include: [
                  { relation: 'slide', scope: { include: 'lesson' } },
                  {
                    relation: 'questions',
                    scope: {
                      where: { isDeleted: false, isEnabled: true },
                      order: ['position ASC'],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      onSuccess: data => {
        this.setQuestionnaireStatus(data)
        onSuccess && onSuccess(this.questionnaireStatus)
      },
    })
  }

  countQuestionnairesToCorrect = user => {
    if (!user?.isAdvisor) return

    this.apiCall({
      resource: 'accounts',
      endpoint: 'questionnairesToCorrect',
      params: { id: user.id },
      getCount: true,
      onSuccess: ({ count }) => {
        this.setQuestionnairesToCorrectCount(count)
      },
    })
  }

  loadQuestionnaireStatuses = ({
    user,
    type = 'pending',
    onSuccess,
    onError,
  }) => {
    if (!user?.isAdvisor) return
    if (!['all', 'corrected', 'pending'].includes(type)) return
    if (this.loadingQuestionnaireStatuses) return

    this.setLoadingQuestionnaireStatuses(true)

    if (this.questionnaireStatusesType !== type) {
      this.setQuestionnaireStatusesType(type)
      this.setPage(1)
    }

    this.setSort(type === 'corrected' ? 'correctedAt' : 'submittedAt', false)

    this.getList({
      resource: 'accounts',
      endpoint: 'questionnaires',
      query: { type },
      params: { id: user.id },
      usePagination: true,
      useSort: true,
      onSuccess: ({ questionnaireStatuses, count }) => {
        this.setQuestionnaireStatuses(questionnaireStatuses)
        this.setQuestionnaireStatusesCount(count)
        if (onSuccess) onSuccess()
      },
      onError,
      onFinish: () => {
        this.setLoadingQuestionnaireStatuses(false)
      },
    })
  }

  correctQuestionnaire = ({
    comments,
    messages = {
      success: 'success',
      error: 'error',
    },
    onSuccess,
    onError,
    onFinish,
  }) => {
    if (!this.courseStatus || !this.questionnaireStatus) return

    const { questionnaireId } = this.questionnaireStatus

    this.apiCall({
      resource: 'courseStatuses',
      endpoint: 'correctQuestionnaire',
      data: {
        comments,
        questionnaireId,
      },
      params: {
        id: this.courseStatus.id,
      },
      onSuccess: ({ answers, correctedAt }) => {
        this.questionnaireStatus.update({ answers, correctedAt })
        this.setMessage(messages.success)
        this.setError(false)
        if (onSuccess) onSuccess(this.courseStatus)
      },
      onError: error => {
        this.setMessage(error.code)
        this.setError(true)
        if (onError) onError(error)
      },
      onFinish,
    })
  }
}

decorate(QuestionnairesStore, {
  courseStatus: observable,
  questionnaireStatus: observable,
  questionnaireStatuses: observable,
  questionnaireStatusesCount: observable,
  questionnaireStatusesType: observable,
  questionnairesToCorrectCount: observable,
  loadingQuestionnaireStatuses: observable,

  setCourseStatus: action,
  setQuestionnaireStatus: action,
  setQuestionnaireStatuses: action,
  setQuestionnaireStatusesCount: action,
  setQuestionnaireStatusesType: action,
  setQuestionnairesToCorrectCount: action,
  setLoadingQuestionnaireStatuses: action,
})
