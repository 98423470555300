import { action, decorate, observable } from 'mobx'

import Advisor from 'models/Advisor'

export default class Answer {
  constructor({ advisor, ...args }) {
    Object.assign(this, args)

    this.advisor = advisor && new Advisor(advisor)
  }

  // Observables

  id = null
  isEnabled = true
  isDeleted = false
  createdAt = new Date()
  updatedAt = new Date()

  advisorId
  advisorNotes
  questionId
  value

  advisor

  // Actions

  setAdvisorNotes = ({ advisor, advisorId, advisorNotes }) => {
    if (advisor) {
      this.advisor = new Advisor(advisor)
      this.advisorId = advisor.id
    } else if (advisorId) {
      this.advisorId = advisorId
    }
    this.advisorNotes = advisorNotes
  }
}

decorate(Answer, {
  id: observable,
  isEnabled: observable,
  isDeleted: observable,
  createdAt: observable,
  updatedAt: observable,

  advisorId: observable,
  advisorNotes: observable,
  questionId: observable,
  value: observable,

  advisor: observable,

  setAdvisorNotes: action,
})
