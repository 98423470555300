import { action, computed, decorate, observable } from 'mobx'

import Role from 'models/Role'
import { arrayOf } from 'utils/arrays'

export default class User {
  // Observables
  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  gender
  username
  firstName
  lastName
  email
  emailVerified
  birthday
  avatar
  street
  postalCode
  city
  regionId
  countryCode
  phone
  fax

  preferences = {}
  lastLoginAt
  lastLoginFrom
  loginClients
  defaultSourceLocaleId
  locale
  entityId
  roles = []

  region
  country

  constructor({ roles, ...args } = {}) {
    Object.assign(this, args)

    this.setRoles(roles)
  }

  // Computed

  get fullName() {
    if (this.firstName || this.lastName) {
      return `${this.firstName} ${this.lastName}`.trim()
    } else {
      return this.username
    }
  }

  get isAdvisor() {
    return this.roles.find(r => r.name === 'advisor') != null
  }

  get isStudent() {
    return this.roles.find(r => r.name === 'student') != null
  }

  get isVerified() {
    return this.emailVerified === true
  }

  get profileUrl() {
    return `/users/details/${this.id}`
  }

  get requireAdmin() {
    for (const role of this.roles) {
      if (role.requireAdmin === true) return true
    }

    return false
  }

  // Actions

  update({ roles, ...args } = {}) {
    Object.assign(this, args)

    this.setRoles(roles)
  }

  setDeleted = value => (this.deleted = value)

  setEnabled = value => (this.enabled = value)

  setRoles = roles => (this.roles = arrayOf({ model: Role, withItems: roles }))

  // Helpers

  isAllowed = roles =>
    roles === undefined || this.roles.some(r => roles.includes(r.name))
}

decorate(User, {
  enabled: observable,
  deleted: observable,
  updatedAt: observable,

  gender: observable,
  firstName: observable,
  lastName: observable,
  email: observable,
  emailVerified: observable,
  birthday: observable,
  avatar: observable,
  street: observable,
  postalCode: observable,
  city: observable,
  regionId: observable,
  countryCode: observable,
  phone: observable,
  fax: observable,

  preferences: observable,
  lastLoginAt: observable,
  lastLoginFrom: observable,
  loginClients: observable,
  defaultSourceLocaleId: observable,
  locale: observable,
  entityId: observable,
  roles: observable,

  region: observable,
  country: observable,

  fullName: computed,
  isAdvisor: computed,
  isStudent: computed,
  isVerified: computed,
  profileUrl: computed,
  requireAdmin: computed,

  update: action,
  setDeleted: action,
  setEnabled: action,
  setRoles: action,
})
