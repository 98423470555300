import { action, computed, decorate, observable } from 'mobx'

import appConf from 'config/app'
import ApiStore from 'stores/ApiStore'
import { arrayOf } from 'utils/arrays'
import { isFunction } from 'utils/types'

import Contact from './Contact'
import Message from './Message'

const { sseNofitications } = appConf.apiEndpoints

export default class MessagesStore extends ApiStore {
  constructor() {
    super({
      model: Message,
      defaultResource: 'messages',
    })
  }

  // Observables

  contactId = null
  contacts = []
  contactsFilter = null

  messages = []
  messageFilter = null
  messagesToRead = 0

  sse

  sseSubscribe = user => {
    if (!user) return

    this.sse = new EventSource(`${sseNofitications.url}/${user.id}`)

    // When a message has been read
    this.sse.addEventListener(
      'MESSAGE_READ',
      ({ data }) => {
        const { messageId } = JSON.parse(data) // eslint-disable-line no-unused-vars
        this.setMessagesToRead(this.messagesToRead - 1)
      },
      false
    )

    // When a message has been unread
    this.sse.addEventListener(
      'MESSAGE_UNREAD',
      ({ data }) => {
        const { messageId } = JSON.parse(data) // eslint-disable-line no-unused-vars
        this.setMessagesToRead(this.messagesToRead + 1)
      },
      false
    )

    // When a new message has been recieved
    this.sse.addEventListener(
      'NEW_MESSAGE',
      ({ data }) => {
        const { senderId } = JSON.parse(data)

        this.setMessagesToRead(this.messagesToRead + 1)

        if (senderId === this.contactId) {
          this.loadMessages({ user, contact: this.contact })
          this.contact.setMessagesToRead(this.contact.messagesToRead + 1)
        }
      },
      false
    )
  }

  sseUnsubscribe = () => {
    if (this.sse) {
      this.sse.close()
    }
  }

  // Computed

  get filteredContacts() {
    return this.contactsFilter !== null
      ? this.contacts.filter(({ username, email, firstName, lastName }) => {
          const fieldsToMatch = [firstName, lastName, username, email]
          const term = `${this.contactsFilter}`.toLowerCase()
          const matches = fieldsToMatch.filter(value => {
            return typeof value === 'string' && value.toLowerCase().match(term)
          })
          return matches.length > 0
        })
      : []
  }

  get contact() {
    return this.contactId
      ? this.contacts.find(contact => contact.id === this.contactId)
      : null
  }

  // Actions

  addMessage = (data, position = 'end') => {
    const message = new Message(data)

    if (position === 'start') {
      this.messages.unshift(message)
    } else {
      this.messages.push(message)
    }
  }

  setMessages = messages => {
    this.messages = arrayOf({
      model: Message,
      withItems: messages,
    })
  }

  setMessagesToRead = value => {
    this.messagesToRead = value
  }

  setContactId = id => {
    this.contactId = id
  }

  setContacts = items => {
    this.contacts = arrayOf({
      model: Contact,
      withItems: items,
    })
  }

  setContactsFilter = value => {
    this.contactsFilter = value !== '' ? value : null
  }

  appendMessages = messages => {
    this.messages = [
      ...this.messages,
      ...arrayOf({
        model: Message,
        withItems: messages,
      }),
    ]
  }

  // Others

  loadMessages = ({
    user,
    contact,
    onSuccess,
    loadMore = false,
    limit = 20,
  } = {}) => {
    if (!contact || !user) return

    const resource = user.isAdvisor ? 'accounts' : 'students'
    const userType = user.isAdvisor ? 'Account' : 'Student'
    const contactType = contact.isAdvisor ? 'Account' : 'Student'

    this.getList({
      resource,
      endpoint: 'messages',
      params: { id: user.id },
      getTotal: true,
      query: {
        filter: {
          where: {
            or: [
              {
                senderId: contact.id,
                senderType: contactType,
                'receivers.receiverId': user.id,
                'receivers.receiverType': userType,
              },
              {
                senderId: user.id,
                senderType: userType,
                'receivers.receiverId': contact.id,
                'receivers.receiverType': contactType,
              },
            ],
          },
          order: ['createdAt DESC'],
          limit: limit,
          offset: loadMore ? this.messages.length : 0,
        },
      },
      onSuccess: data => {
        if (loadMore) {
          this.appendMessages(data)
        } else {
          this.setMessages(data)
        }
        onSuccess && onSuccess(this.messages)
      },
    })
  }

  countMessagesToRead = user => {
    this.apiCall({
      resource: user.isStudent ? 'students' : 'accounts',
      endpoint: 'messages',
      params: { id: user.id },
      query: {
        where: {
          'receivers.receiverId': user.id,
          'receivers.readAt': { eq: null },
        },
      },
      getCount: true,
      onSuccess: ({ count }) => this.setMessagesToRead(count),
    })
  }

  loadContacts = ({ onError, onSuccess, user }) => {
    if (!user) return
    const { id, isStudent } = user

    this.getList({
      resource: isStudent ? 'students' : 'accounts',
      endpoint: 'messageContacts',
      params: { id },
      onSuccess: data => {
        this.setContacts(data)
        onSuccess && onSuccess()
      },
      onError,
    })
  }

  createMessage = ({ onError, onSuccess, user, contact, ...data }) => {
    if (!user || !contact) return

    this.apiCall({
      resource: user.isStudent ? 'students' : 'accounts',
      endpoint: 'createMessage',
      params: { id: user.id },
      data: {
        ...data,
        receivers: [
          {
            receiverId: contact.id,
            receiverType: contact.isStudent ? 'Student' : 'Account',
          },
        ],
      },
      onSuccess: data => {
        this.addMessage(data, 'start')
        onSuccess && onSuccess(data)
      },
      onError,
    })
  }

  markAllMessages = ({ onError, onSuccess, user, receiver, asRead }) => {
    if (!(user || receiver)) return

    this.apiCall({
      resource: user.isStudent ? 'students' : 'accounts',
      endpoint: 'markMessages',
      params: { id: user.id },
      data: { receiverId: receiver.receiverId, asRead },
      onSuccess: () => {
        receiver.setRead(false)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
    })
  }

  toggleMessageRead = ({ message, onError, onSuccess, user, receiver }) => {
    if (!(user || receiver || message)) return

    this.apiCall({
      resource: user.isStudent ? 'students' : 'accounts',
      endpoint: receiver.isRead ? 'unreadMessage' : 'readMessage',
      params: { id: user.id },
      data: { messageId: message.id },
      onSuccess: () => {
        // receiver.setRead(!receiver.isRead)
        isFunction(onSuccess) && onSuccess()
      },
      onError,
    })
  }

  getContactMessagesToRead = ({ onSuccess, onError, user, contact }) => {
    this.apiCall({
      resource: user.isAdvisor ? 'accounts' : 'students',
      endpoint: 'messages',
      params: { id: user.id },
      query: {
        where: {
          senderId: contact.id,
          'receivers.receiverId': user.id,
          'receivers.readAt': { eq: null },
        },
      },
      getCount: true,
      onSuccess,
      onError,
    })
  }
}

decorate(MessagesStore, {
  contactId: observable,
  contactsFilter: observable,
  contacts: observable,
  messages: observable,
  messagesToRead: observable,

  contact: computed,
  filteredContacts: computed,

  setContactId: action,
  setContacts: action,
  setContactsFilter: action,
  addMessage: action,
  appendMessages: action,
  setMessages: action,
  setMessagesToRead: action,
})
