import { decorate, observable } from 'mobx'

import Advisor from 'models/Advisor'
import Student from 'models/Student'

import MessageReceiver from './MessageReceiver'

import { arrayOf, isArray } from 'utils/arrays'

export default class Message {
  constructor({ sender, receivers = [], ...args }) {
    Object.assign(this, args)

    this.sender =
      args.senderType === 'Account' ? new Advisor(sender) : new Student(sender)

    this.receivers = arrayOf({
      model: MessageReceiver,
      withItems: receivers,
    })
  }

  // Observables

  id = null
  createdAt = new Date()
  updatedAt = new Date()

  body
  receivers = []
  sender
  subject

  // Helpers

  // receiverType: 'Account' or '
  getReceiverByIdAndType = ({ receiverId, receiverType }) => {
    return (
      isArray(this.receivers) &&
      this.receivers.find(
        r =>
          r.receiverId.toString() === receiverId.toString() &&
          r.receiverType.toString() === receiverType.toString()
      )
    )
  }
}

decorate(Message, {
  id: observable,
  createdAt: observable,
  updatedAt: observable,

  body: observable,
  receivers: observable,
  sender: observable,
  subject: observable,
})
