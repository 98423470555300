import { action, decorate, observable } from 'mobx'

class ApiState {
  // Observables:

  offline = false
  lastApiCall = null
  lastApiError = null

  // Actions:

  setLastApiCall = error => {
    const now = new Date()
    const milisecondsToWait = 2000
    const isEnoughWaitTime = +now > +this.lastApiCall + milisecondsToWait

    if (!this.lastApiCall || isEnoughWaitTime) {
      this.lastApiCall = now
    }

    this.lastApiError = error

    switch (error) {
      case 'SERVER_OFFLINE':
        this.offline = true
        break

      default:
        this.offline = false
        break
    }
  }
}
decorate(ApiState, {
  offline: observable,
  lastApiCall: observable,
  lastApiError: observable,

  setLastApiCall: action,
})

// Initialize and export ApiState as a singleton object
const apiState = new ApiState()

export default apiState
