import { action, computed, decorate, observable } from 'mobx'

import Entity from 'models/Entity'
import ApiStore from 'stores/ApiStore'

export default class EntityStore extends ApiStore {
  constructor() {
    super({
      model: Entity,
      defaultResource: 'entities',
    })
  }

  // Observables

  entity = null
  entityCountries = []
  entityCountry = null

  // Computed

  get countryRegions() {
    if (!this.entityCountry) return undefined
    const { scopeType, scopes } = this.entityCountry
    return scopeType === 'region' ? scopes : undefined
  }

  // Actions

  setEntity = data => {
    this.entity = data && new Entity(data)
  }

  setEntityCountries = (items = []) => {
    this.entityCountries = items?.length > 0 ? items : []
  }

  setEntityCountry = data => {
    this.entityCountry = data
  }

  loadEntity = ({ onSuccess, field = null } = {}) => {
    this.getItem({
      endpoint: 'currentEntity',
      query: {
        fields: field
          ? [field]
          : [
              'id',
              'siteTitle',
              'siteDescription',
              'logo',
              'favicon',
              'social',
              'language',
              'scripts',
              'theme',
              'links',
              'showLegalLinks',
              'copyright',
              'newsletterEnabled',
              'eventAdvertisementEnabled',
              'nameFieldsEnabled',
              'acceptTermsOfUse',
              'acceptNewsletter',
              'acceptEventAdvertisement',
            ],
      },
      onSuccess: data => {
        this.setEntity(field ? { ...this.entity, [field]: data[field] } : data)
        onSuccess && onSuccess(this.entity)
      },
    })
  }

  // Others

  loadEntityCountries = ({ onSuccess } = {}) => {
    this.getList({
      endpoint: 'countries',
      params: { id: this.entity.id },
      query: {
        filter: {
          where: {
            isDeleted: false,
            isEnabled: true,
          },
          include: {
            relation: 'scopes',
            scope: {
              where: {
                isDeleted: false,
                isEnabled: true,
              },
              order: 'config.name ASC',
            },
          },
        },
      },
      onSuccess: data => {
        this.setEntityCountries(data)
        onSuccess && onSuccess(this.entityCountries)
      },
    })
  }
}

decorate(EntityStore, {
  entity: observable,
  entityCountry: observable,
  entityCountries: observable,

  countryRegions: computed,

  setEntity: action,
  setEntityCountry: action,
  setEntityCountries: action,
})
