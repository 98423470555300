import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { observer, useLocalStore } from 'mobx-react'

import CookiesConsentStore from './CookiesConsentStore'

const CookiesConsentContext = React.createContext()

export const CookiesConsentProvider = observer(({ children }) => {
  const store = useLocalStore(() => new CookiesConsentStore())

  // Get previous cookies consent
  useEffect(() => {
    store.retrieveCookiesConsent()
  }, [store])

  return (
    <CookiesConsentContext.Provider value={store}>
      {children}
    </CookiesConsentContext.Provider>
  )
})

CookiesConsentProvider.propTypes = {
  children: PropTypes.node,
}

export function useCookiesConsentContext() {
  return useContext(CookiesConsentContext)
}
