import { action, decorate, observable } from 'mobx'

import appConf from 'config/app'
import { apiCall } from 'stores/ApiStore'
import i18n, { locales } from 'config/i18n'

const { clientToken, sseNofitications } = appConf.apiEndpoints

const { NODE_ENV } = process.env

export default class AppState {
  constructor(args) {
    Object.assign(this, args)
  }

  // Observables:

  loading = false
  locale = 'en'
  isUpToDate = true
  version = null
  redirectUrl = null
  siteDescription = 'Online Courses'
  siteLogo = 'images/logo.svg'
  siteFavicon = 'favicon.ico'
  siteTitle = 'Hope Courses'
  siteHasApp = false
  isNativeApp = navigator.userAgent.includes('gonative')

  // Actions:

  setLoading = value => (this.loading = !!value)

  setLocale = locale => {
    if (locales.includes(locale)) {
      this.locale = locale
      i18n.changeLanguage(locale)
    }
  }

  setRedirectUrl = value => (this.redirectUrl = value)

  setSiteDescription = value => (this.siteDescription = value)

  setSiteLogo = value => (this.siteLogo = value)

  setSiteFavicon = value => (this.siteFavicon = value)

  setSiteTitle = value => (this.siteTitle = value)

  setSiteHasApp = value => (this.siteHasApp = value)

  setVersion = value => (this.version = value)

  setIsUpToDate = version => (this.isUpToDate = this.version === version)

  // SSE (Server Send Events)

  subscribe = () => {
    const { userId } = this.session || {}
    if (!userId) return

    this.isReconnect = false
    this.sse = new EventSource(`${sseNofitications.url}/${userId}`)

    this.sse.addEventListener(
      'open',
      () => {
        if (this.isReconnect) {
          this.getClientVersion({ isInitialLoad: false })
        }
      },
      false
    )

    // When we got disconnected from the sse
    this.sse.addEventListener(
      'error',
      e => {
        if (e.target.readyState === EventSource.CONNECTING) {
          this.isReconnect = true
        }
      },
      false
    )

    // When the client version has been updated
    this.sse.addEventListener(
      'CLIENT_VERSION_UPDATE',
      e => {
        const data = JSON.parse(e.data)
        if (data.token === clientToken)
          this.getClientVersion({ isInitialLoad: false })
      },
      false
    )
  }

  unsubscribe = () => {
    if (this.sse) this.sse.close()
  }

  // Others

  getClientVersion = ({ isInitialLoad = true } = {}) => {
    apiCall({
      resource: 'clients',
      endpoint: 'getVersion',
      onSuccess: version => {
        if (isInitialLoad) {
          this.setVersion(version)
        } else {
          this.setIsUpToDate(version)
        }
      },
    })
  }

  reportError = (error, errorInfo) => {
    if (NODE_ENV !== 'development') {
      apiCall({
        resource: 'clients',
        endpoint: 'reportError',
        data: { error: error?.toString(), errorInfo },
        onSuccess: response => console.info('Error was reported!', response), // eslint-disable-line no-console
      })
    }
  }
}

decorate(AppState, {
  isUpToDate: observable,
  loading: observable,
  locale: observable,
  redirectUrl: observable,
  siteDescription: observable,
  siteLogo: observable,
  siteFavicon: observable,
  siteTitle: observable,
  siteHasApp: observable,
  version: observable,
  isNativeApp: observable,

  setIsUpToDate: action,
  setLoading: action,
  setLocale: action,
  setRedirectUrl: action,
  setServerOffline: action,
  setSiteDescription: action,
  setSiteLogo: action,
  setSiteFavicon: action,
  setSiteTitle: action,
  setSiteHasApp: action,
  setVersion: action,
})
