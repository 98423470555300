import {
  FaAsterisk,
  FaBirthdayCake,
  FaCalendarAlt,
  FaCheckCircle,
  FaCheckSquare,
  FaCircle,
  FaEnvelope,
  FaEnvelopeOpen,
  FaFax,
  FaMapPin,
  FaRegCircle,
  FaRegDotCircle,
  FaRegTrashAlt,
  FaSquare,
  FaSignOutAlt,
  FaTrashAlt,
} from 'react-icons/fa'
import { BiLoaderAlt } from 'react-icons/bi'
import {
  FiAlertCircle,
  FiBell,
  FiBook,
  FiBookOpen,
  FiCalendar,
  FiCheck,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
  FiChevronUp,
  FiClock,
  FiDownloadCloud,
  FiEdit,
  FiExternalLink,
  FiFileText,
  FiGlobe,
  FiInbox,
  FiHeart,
  FiHelpCircle,
  FiKey,
  FiLayers,
  FiLink,
  FiList,
  FiLock,
  FiMail,
  FiMenu,
  FiMessageCircle,
  FiMinus,
  FiMusic,
  FiPhone,
  FiPlayCircle,
  FiRss,
  FiSearch,
  FiShare2,
  FiSquare,
  FiXSquare,
  FiUser,
  FiUsers,
  FiUserCheck,
  FiVideo,
  FiVolume2,
  FiX,
} from 'react-icons/fi'

export const linkIcons = {
  audio: FiVolume2,
  book: FiBook,
  'book-open': FiBookOpen,
  calendar: FiCalendar,
  'file-text': FiFileText,
  globe: FiGlobe,
  heart: FiHeart,
  layers: FiLayers,
  link: FiLink,
  'message-circle': FiMessageCircle,
  music: FiMusic,
  user: FiUser,
  users: FiUsers,
  'user-check': FiUserCheck,
  video: FiVideo,
}

export const uiIcons = {
  'alert-circle': FiAlertCircle,
  asterisk: FaAsterisk,
  bell: FiBell,
  'birthday-cake': FaBirthdayCake,
  'calendar-alt': FaCalendarAlt,
  check: FiCheck,
  'check-circle': FaCheckCircle,
  'check-square': FaCheckSquare,
  clock: FiClock,
  close: FiX,
  'chevron-down': FiChevronDown,
  'chevron-left': FiChevronLeft,
  'chevron-right': FiChevronRight,
  'chevron-up': FiChevronUp,
  'chevrons-left': FiChevronsLeft,
  'chevrons-right': FiChevronsRight,
  circle: FaRegCircle,
  'circle-dot': FaRegDotCircle,
  'circle-fill': FaCircle,
  download: FiDownloadCloud,
  edit: FiEdit,
  'external-link': FiExternalLink,
  envelope: FaEnvelope,
  'envelope-open': FaEnvelopeOpen,
  fax: FaFax,
  'help-circle': FiHelpCircle,
  inbox: FiInbox,
  key: FiKey,
  list: FiList,
  loader: BiLoaderAlt,
  lock: FiLock,
  logout: FaSignOutAlt,
  mail: FiMail,
  'map-pin': FaMapPin,
  menu: FiMenu,
  minus: FiMinus,
  phone: FiPhone,
  'play-circle': FiPlayCircle,
  rss: FiRss,
  search: FiSearch,
  share: FiShare2,
  square: FiSquare,
  'square-x': FiXSquare,
  'square-fill': FaSquare,
  trash: FaTrashAlt,
  'trash-alt': FaRegTrashAlt,
}

export default { ...linkIcons, ...uiIcons }
