import { computed, decorate, observable } from 'mobx'

import appConf from 'config/app'

export default class Entity {
  constructor(args) {
    Object.assign(this, args)
  }

  // Observables
  id = null
  isEnabled = true
  isDeleted = false
  address = {}
  description = ''
  imprint = null
  logo = null
  favicon = null
  name = ''
  email = ''
  phone = ''
  links = []
  scripts = []
  privacy = null
  siteDescription = ''
  siteTitle = ''
  showLegalLinks = true
  social = {}
  theme = {}
  termsOfUse = null
  website = ''

  // Computed

  get scriptsWithCookies() {
    return this.scripts.filter(script => script.hasCookies)
  }

  get functionalScripts() {
    return this.getScriptsOfType('functional')
  }

  get trackingScripts() {
    return this.getScriptsOfType('tracking')
  }

  get targetingScripts() {
    return this.getScriptsOfType('targeting')
  }

  get hasFunctionalityCookies() {
    return this.hasCookiesScriptsOfType('functional')
  }

  get hasTrackingCookies() {
    return this.hasCookiesScriptsOfType('tracking')
  }

  get hasTargetingCookies() {
    return this.hasCookiesScriptsOfType('targeting')
  }

  get logoURL() {
    if (!this.logo) return null

    const { cdnUrl } = appConf.apiEndpoints
    const { resourceName, containerId, name, customName, extension } = this.logo

    return `${cdnUrl}/documents/${resourceName}/${containerId}/${name}/${customName}${extension}`
  }

  get faviconURL() {
    if (!this.favicon) return null

    const { cdnUrl } = appConf.apiEndpoints
    const { resourceName, containerId, name, customName, extension } =
      this.favicon

    return `${cdnUrl}/documents/${resourceName}/${containerId}/${name}/${customName}${extension}`
  }

  // Helpers

  getScriptsOfType(type) {
    return this.scriptsWithCookies.filter(
      script => script.consentLevel === type
    )
  }

  hasCookiesScriptsOfType(type) {
    return this.getScriptsOfType(type).length > 0
  }
}

decorate(Entity, {
  id: observable,
  enabled: observable,
  deleted: observable,

  address: observable,
  description: observable,
  imprint: observable,
  logo: observable,
  favicon: observable,
  name: observable,
  email: observable,
  links: observable,
  phone: observable,
  privacy: observable,
  siteDescription: observable,
  siteTitle: observable,
  scripts: observable,
  social: observable,
  termsOfUse: observable,
  website: observable,

  scriptsWithCookies: computed,
  hasTrackingCookies: computed,
})
