import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { observer, useLocalStore } from 'mobx-react'

import AppState from './AppState'

const APP_VERSION_CHECK_INTERVAL = 1000 * 60 * 60 // Check app version every hour (1000ms * 60sec * 60min)

const AppContext = React.createContext()

// Init singleton store
export const appState = new AppState()

export const AppProvider = observer(({ children }) => {
  const store = useLocalStore(() => appState)
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>
})

AppProvider.propTypes = {
  children: PropTypes.node,
}

export function useAppContext() {
  return useContext(AppContext)
}

export function useLoading(loading) {
  const { setLoading } = useAppContext()

  useEffect(() => {
    setLoading(loading)
  }, [setLoading, loading])
}

export function useAppVersionCheck() {
  const appState = useAppContext()

  useEffect(() => {
    // Check for app's new version:
    // - Check it immediately
    appState.getClientVersion({ isInitialLoad: true })

    // - And set an interval to check it regularly
    setInterval(() => {
      appState.getClientVersion({ isInitialLoad: false })
    }, APP_VERSION_CHECK_INTERVAL)
  }, [appState])

  return appState.version
}
