import { action, decorate, observable } from 'mobx'
const cookiesConsentKey = 'cookiesConsent'

export default class CookiesConsentStore {
  constructor(args) {
    Object.assign(this, args)
  }

  // Observables:

  functional = false
  necessary = false
  targeting = false
  tracking = false

  showSettings = true

  // Actions:

  setCookiesConsent = (value, storeLocal = true) => {
    const { necessary, functional, tracking, targeting } = value

    this.necessary = necessary
    this.functional = functional
    this.targeting = targeting
    this.tracking = tracking

    this.setShowSettings(!this.necessary)

    if (storeLocal) {
      this.storeCookiesConsent(value)
    }
  }

  setShowSettings = value => (this.showSettings = !!value)

  storeCookiesConsent = value => {
    localStorage.setItem(cookiesConsentKey, JSON.stringify(value))
  }

  retrieveCookiesConsent = async () => {
    const cookiesConsent = await JSON.parse(
      await localStorage.getItem(cookiesConsentKey)
    )

    if (cookiesConsent) {
      this.setCookiesConsent(cookiesConsent, false)
    } else {
      this.setShowSettings(!this.necessary)
    }
  }
}

decorate(CookiesConsentStore, {
  functional: observable,
  necessary: observable,
  targeting: observable,
  tracking: observable,
  showSettings: observable,

  setCookiesConsent: action,
  setShowCookiesConsent: action,
})
